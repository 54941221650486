import React, { useContext } from "react";

import RaisedButton from "../components/RaisedButton";

import { TooltipView } from "../components/TooltipView";

import {
  Button,
  Divider,
  Flex,
  Grid,
  Loader,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { LessonContext } from "../features/lesson/components/LessonContextProvider";
import {
  FaFilePowerpoint,
  FaGoogleDrive,
  FaLink,
  FaUpload,
} from "react-icons/fa";
import ViewLinkMenu from "./ShareLessonButton.ViewLinkMenu";
import useClickOutsideDetection from "../hooks/useClickOutsideDetection";
import { PresentationContext } from "../features/lesson/components/PresentationContextProvider";
import { SlidesContext } from "../features/lesson/components/SlidesContextProvider";

export default function ShareLessonButton() {
  const { lesson, isOwner } = useContext(LessonContext);
  const { slides } = React.useContext(SlidesContext);

  const [menu, setMenu] = React.useState<"main" | "view-link">("main");
  const [show, setShow] = React.useState(false);

  const {
    exportToPowerPoint,
    exportToGoogleSlides,
    googleExporting,
    pptxExporting,
  } = React.useContext(PresentationContext);

  // const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const buttonRef = useClickOutsideDetection<HTMLButtonElement>(
    (e) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      if (show) {
        setShow(false);
      }
    },
    [show]
  );

  type ShareButtonProps = {
    onClick: () => void | Promise<void>;
    icon: React.ReactNode;
    label: React.ReactNode;
    isLoading?: boolean;
    posthogLabel?: string;
  };

  const ShareButton = ({
    onClick,
    icon,
    label,
    isLoading,
    posthogLabel,
  }: ShareButtonProps) => (
    <Button
      backgroundColor={"white"}
      padding={"xxs xs"}
      direction={"column"}
      variation="link"
      alignContent={"center"}
      justifyContent={"flex-start"}
      isDisabled={isLoading}
      gap={"xxs"}
      onClick={() => {
        onClick();
      }}
      data-attr="share-lesson-slides"
      data-ph-capture-attribute-type={posthogLabel}
      data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
      data-ph-capture-attribute-title={lesson.title.toLowerCase()}
      data-ph-capture-attribute-grade-level={lesson.gradeLevel}
      data-ph-capture-attribute-reading-level={lesson.readingLevel}
      data-ph-capture-attribute-language={lesson.lang}
      data-ph-capture-attribute-number-of-slides={slides.length}
    >
      <Flex
        width={"50px"}
        borderRadius={"100px"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"neutral.80"}
        className="exportButton"
        style={{
          aspectRatio: "1",
        }}
      >
        {isLoading ? <Loader /> : icon}
      </Flex>
      <View>{label}</View>
    </Button>
  );

  const MainMenu = () => (
    <Flex direction="column" padding={"xs"} gap={"xs"}>
      <Text textAlign={"left"} fontWeight={"bold"}>
        Share this lesson
      </Text>
      <Divider
        padding={"0"}
        style={{ borderColor: "var(--amplify-colors-neutral-60" }}
        size="small"
      />
      <Grid
        gap={"xxxs"}
        padding={"0"}
        templateColumns={isOwner ? "1fr 1fr 1fr" : "1fr 1fr"}
      >
        {isOwner ? (
          <ShareButton
            onClick={async () => {
              setMenu("view-link");
            }}
            icon={<FaLink fontSize={"medium"} />}
            label={
              <Text fontSize={"xs"} fontWeight={"semibold"}>
                Public
                <br /> View Link
              </Text>
            }
            posthogLabel="create-public-view-link"
          ></ShareButton>
        ) : null}
        <ShareButton
          isLoading={googleExporting}
          onClick={async () => {
            await exportToGoogleSlides();
            setShow(false);
          }}
          icon={<FaGoogleDrive fontSize={"medium"} />}
          label={
            <Text fontSize={"xs"} fontWeight={"semibold"}>
              Google
              <br /> Drive
            </Text>
          }
          posthogLabel="google-drive"
        ></ShareButton>
        <ShareButton
          isLoading={pptxExporting}
          onClick={async () => {
            await exportToPowerPoint();
            setShow(false);
          }}
          icon={<FaFilePowerpoint fontSize={"medium"} />}
          label={
            <Text fontWeight={"semibold"} fontSize={"xs"}>
              PowerPoint
            </Text>
          }
          posthogLabel="powerpoint"
        ></ShareButton>
      </Grid>
      {/* {lessonLinks && lessonLinks?.length > 0 ? (
            <Text>{lessonLinks[0].linkId}</Text>
          ) : null} */}
    </Flex>
  );
  return (
    <TooltipView
      trigger={"click"}
      show={show}
      onToggle={(show) => setShow(show)}
      placement="bottom-end"
      tooltipChildren={
        <View
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {menu === "main" ? (
            <MainMenu />
          ) : (
            <ViewLinkMenu lessonId={lesson.id} onBack={() => setMenu("main")} />
          )}
        </View>
      }
    >
      <RaisedButton
        ref={buttonRef}
        backgroundColor={"white"}
        color={"black"}
        gap={"xs"}
        variation="primary"
        size="small"
        onClick={() => setMenu("main")}
      >
        <FaUpload />
        Share
      </RaisedButton>
    </TooltipView>
  );
}
