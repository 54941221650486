import * as React from "react";
import { Slide, SlideElement } from "../types/slideTypes";
import { useEffect } from "react";
import { SlidesContext } from "./SlidesContextProvider";
import useArray from "../../../hooks/useArray";

export interface SlideElementsContextProps {
  slideHeightPx: number;
  slideWidthPx: number;
  elements: SlideElement[];
  selectedElement: SlideElement | null; // newly added
  setSelectedElement: React.Dispatch<React.SetStateAction<SlideElement | null>>; // newly added
  selectedElements: string[];
  addSelectedElement: (elementId: string) => void;
  removeSelectedElement: (elementId: string) => void;
  selectElement: (elementId: string) => void;
  onChangeElement: (element: SlideElement) => void;
  idPrefix: string;
  isStatic: boolean;
  isImageLoading?: boolean;
}

export const SlideElementsContext =
  React.createContext<SlideElementsContextProps>(
    {} as SlideElementsContextProps
  );

export interface ISlideElementsContextProviderProps {
  children: React.ReactNode;
  slideRef: React.RefObject<HTMLDivElement>;
  slide: Slide;
  idPrefix: string;
  isStatic: boolean;
  isImageLoading?: boolean;
}

export default function SlideElementsContextProvider(
  props: ISlideElementsContextProviderProps
) {
  const { children, slideRef, slide, idPrefix, isStatic, isImageLoading } =
    props;

  const { setSlideElement } = React.useContext(SlidesContext);

  const [slideWidthPx, setSlideWidthPx] = React.useState(0); // New state to track editing mode
  const [slideHeightPx, setSlideHeightPx] = React.useState(0); // New state to track editing mode

  const resizeSlide = React.useCallback(() => {
    if (slideRef.current === null) return;
    const { width: slideWidth, height: slideHeight } =
      slideRef.current.getBoundingClientRect();
    setSlideWidthPx(() => slideWidth);
    setSlideHeightPx(() => slideHeight);
  }, [slideRef.current]);

  const elements = React.useMemo(() => {
    // const thisSlide = slides.find((s) => s.id === slide.id);
    return slide.elements;
  }, [slide]);

  useEffect(() => {
    resizeSlide();
  }, [slide.elements, resizeSlide]);

  useEffect(() => {
    resizeSlide();

    window.addEventListener("resize", () => resizeSlide());

    // Return a cleanup function to remove the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", () => resizeSlide());
    };
  }, []);

  const [selectedElement, setSelectedElement] =
    React.useState<SlideElement | null>(null);
  const {
    array: selectedElements,
    push: addSelectedElement,
    removeValue: removeSelectedElement,
    clear: clearSelectedElements,
  } = useArray<string>();

  useEffect(() => {

    clearSelectedElements();
  }, [slide.id]);

  const onChangeElement = React.useCallback(
    (element: SlideElement) => {

      setSlideElement(slide.id, element);
    },
    [slide, setSlideElement]
  );

  return (
    <SlideElementsContext.Provider
      value={{
        slideHeightPx,
        slideWidthPx,
        elements,
        selectedElement,
        setSelectedElement,
        selectedElements,
        addSelectedElement,
        removeSelectedElement,
        selectElement: (id: string) => {
          clearSelectedElements();
          addSelectedElement(id);
        },
        onChangeElement,
        idPrefix,
        isStatic,
        isImageLoading,
      }}
    >
      {children}
    </SlideElementsContext.Provider>
  );
}
