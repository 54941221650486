import { Text, Flex, Grid, Card, ScrollView } from '@aws-amplify/ui-react'
import React from 'react'
import { RiDoubleQuotesL } from "react-icons/ri";
import { S3Image } from '../components/S3Image';

export default function TestimonalItem({ testimonial, name, avatar }: { testimonial: string, name: string, avatar: string }) {
    return (


        <Flex
            paddingTop="0"
            borderRadius="large"
            paddingLeft={{ base: "0", small:"medium", xl: "xxxl" }}
            paddingRight={{ base: "0", small:"medium", xl: "xxxl" }}
        >
            <Flex
                paddingLeft={{ base: "small", xl: "xxxl" }}
                paddingRight={{ base: "small", xl: "xxxl" }}
            >
                <ScrollView>
                    <Grid templateColumns={{ base: "1fr", medium: "auto 2fr" }}
                        paddingLeft={{ base: "small", small:"medium", xl: "xxxl" }}
                        paddingRight={{ base: "small", small:"medium", xl: "xxxl" }}
                        gap={{ base: "small", medium: "xl" }}
                        height={"280px"}
                    >

                        <Flex justifyContent={"center"} padding={"xs"}>
                            <Card position={"relative"}
                                width={{ base: "100px", small: "115px", medium: "255px" }}
                                height={{ base: "100px", small: "115px", medium: "255px" }} backgroundColor={"neutral.40"} borderRadius={"100%"}
                                style={{
                                    backgroundImage: "linear-gradient(white ,var(--amplify-colors-neutral-20))",
                                    borderColor: "var(--amplify-colors-teal-60)",
                                    borderWidth: "6px"
                                }} padding={"0px"}
                            >
                                <S3Image
                                    bucket="gibbly-homepage-assets"
                                    s3key={avatar}
                                    borderRadius={"0 0 100% 100%"}
                                    alt="Testimonial avatar"
                                />
                                <Text position="absolute" top={"0"} right={"0"} marginRight={{ base: "-20px", medium: "-45px" }} color="orange.60" lineHeight={"0"} fontSize={{ base: "xxl", medium: "90px" }}>
                                    <RiDoubleQuotesL style={{ stroke: "white", strokeWidth: "1px" }} />
                                </Text>
                            </Card>
                        </Flex>

                        <Flex alignItems={"center"}>
                            <Text
                                paddingRight={"large"}
                                textAlign={"left"}
                                fontSize={{ base: "smaller", small: "small", medium: "medium", large: "larger" }}
                            >
                                <Text
                                    paddingLeft={{ base: "small", large: "large" }}
                                    paddingBottom={{ base: "small", large: "large" }}
                                    color={"white"}
                                >
                                    {testimonial}
                                </Text>
                                <i>
                                    <Text
                                        fontWeight={"bold"}
                                        paddingLeft={"large"}
                                        textAlign={"right"}
                                        color={"white"}
                                    >
                                        {name}
                                    </Text>

                                </i>

                            </Text>
                        </Flex>

                    </Grid>
                </ScrollView>

            </Flex>
        </Flex>




    )
}
