import {
  Flex,
  Heading,
  SelectField,
  TextAreaField,
  Text,
} from "@aws-amplify/ui-react";
import * as React from "react";
import InfoIcon from "../../../../components/icons/InfoIcon";
import { Level } from "../../types/lessonTypes";
import { LessonContext } from "../LessonContextProvider";
import useCharacterLimit from "../../../../hooks/useCharacterLimit";
import LanguageSelect from "../../../../components/LanguageSelect";

export interface ILessonPromptFormProps {
  overwrite?: boolean;
}

export default function LessonPromptForm(props: ILessonPromptFormProps) {
  const { overwrite = true } = props;

  const {
    lesson,
    isOwner,
    setTopic,
    setGradeLevel,
    setReadingLevel,
    setLanguage,
    outputLang,
    setOutputLang,
    // setLanguage,
  } = React.useContext(LessonContext);

  const { characterLimit, numCharacters } = useCharacterLimit(
    lesson.topic,
    setTopic,
    500
  );

  React.useEffect(() => {}, [lesson.lang]);

  const [stateLocal, setStateLocal] = React.useState({
    topic: lesson.topic,
    gradeLevel: lesson.gradeLevel,
    readingLevel: lesson.readingLevel,
  });

  const state = React.useMemo(() => {
    if (overwrite)
      return {
        topic: lesson.topic,
        gradeLevel: lesson.gradeLevel,
        readingLevel: lesson.readingLevel,
      };
    else
      return {
        topic: stateLocal.topic,
        gradeLevel: stateLocal.gradeLevel,
        readingLevel: stateLocal.readingLevel,
      };
  }, [overwrite, lesson, stateLocal]);

  return (
    <Flex direction={"column"} gap={"xs"}>
      <TextAreaField
        rows={1}
        disabled={!isOwner}
        resize="vertical"
        label={
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex alignItems={"center"}>
              <Heading
                fontWeight={"semibold"}
                color={"neutral.100"}
                fontSize={"small"}
              >
                Topic
              </Heading>{" "}
              <InfoIcon
                color={"var(--amplify-colors-neutral-40)"}
                message="Used as prompt for AI generation"
              />
            </Flex>
            <Flex alignItems={"center"}>
              <Text color={"neutral.80"}>
                {numCharacters}/{characterLimit}
              </Text>
            </Flex>
          </Flex>
        }
        value={state.topic}
        fontSize={"smaller"}
        size="small"
        onChange={(e) => {
          if (overwrite) setTopic(e.target.value);
          else setStateLocal((prev) => ({ ...prev, topic: e.target.value }));
        }}
      />

      <Flex wrap={"wrap"} width={"100%"} flex={1}>
        <SelectField
          size="small"
          flex={1}
          disabled={!isOwner}
          label={
            <Heading
              fontSize={"small"}
              fontWeight={"semibold"}
              color={"neutral.100"}
            >
              Grade Level
            </Heading>
          }
          placeholder="Select"
          value={state.gradeLevel}
          onChange={(e) => {
            if (overwrite) setGradeLevel(e.target.value as Level);
            else
              setStateLocal((prev) => ({
                ...prev,
                gradeLevel: e.target.value as Level,
              }));
          }}
        >
          {Object.values(Level).map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </SelectField>
        <SelectField
          size="small"
          flex={1}
          disabled={!isOwner}
          label={
            <Heading
              fontWeight={"semibold"}
              color={"neutral.100"}
              fontSize={"small"}
            >
              Reading Level
            </Heading>
          }
          value={state.readingLevel}
          onChange={(e) => {
            if (overwrite) setReadingLevel(e.target.value as Level);
            else
              setStateLocal((prev) => ({
                ...prev,
                readingLevel: e.target.value as Level,
              }));
          }}
        >
          <option value={undefined}>{"Same as Grade"}</option>
          {Object.values(Level).map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </SelectField>
      </Flex>
      <LanguageSelect
        size="small"
        label={
          <Flex>
            <Heading
              fontWeight={"semibold"}
              color={"neutral.100"}
              fontSize={"small"}
            >
              Output Language
            </Heading>
            <InfoIcon message="Language used by AI to generate text" />
          </Flex>
        }
        isDisabled={!isOwner}
        value={outputLang}
        onChange={(lang) => {
          if (lang === "en" || lang === "fr") setOutputLang(lang);
          else setOutputLang("en");
        }}
      ></LanguageSelect>
      <LanguageSelect
        size="small"
        disableList={[]}
        label={
          <Flex>
            <Heading
              fontWeight={"semibold"}
              color={"neutral.100"}
              fontSize={"small"}
            >
              Current Language
            </Heading>
            <InfoIcon message="Language used by screen reader" />
          </Flex>
        }
        isDisabled={!isOwner}
        value={lesson.lang}
        onChange={(lang) => {
          setLanguage(lang);
        }}
      ></LanguageSelect>
    </Flex>
  );
}
