import { Flex, Grid, Text, View } from "@aws-amplify/ui-react";
import * as React from "react";

import { useQuery } from "@tanstack/react-query";
import { getProductAndPricesQuery } from "../util/query/getProductAndPricesQuery";
import { getCheckoutSession } from "../services/stripe/getCheckoutSession";
import { useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard.new";
import useEnvironment from "../hooks/useEnvironment";
import { useUserContext } from "../context";

export type PremiumPricingCardProps = {
  interval: "month" | "year";
};

const testPremiumProductId = "prod_PEe8ynbzgTjE7D";
const livePremiumProductId = "prod_PIomGnbtITLrzy";

export default function PremiumPricingCard(props: PremiumPricingCardProps) {
  const { interval } = props;

  const { user, isSubscribed } = useUserContext();

  const { environment } = useEnvironment();

  const productId = React.useMemo(
    () =>
      environment === "prod" ? livePremiumProductId : testPremiumProductId,
    [environment]
  );

  const navigate = useNavigate();

  const { data, isLoading: isLoadingProduct } = useQuery(
    getProductAndPricesQuery(productId)
  );

  const { prices } = React.useMemo(
    () =>
      data !== undefined ? data : { product: undefined, prices: undefined },
    [data]
  );

  const price = React.useMemo(() => {
    return prices?.data.find((price) => price.recurring.interval === interval);
  }, [prices, interval]);

  // const cadPrice = React.useMemo(() => {
  //   return price?.currency_options?.cad.unit_amount;
  // }, [price]);

  // const usdPrice = React.useMemo(() => {
  //   return price?.currency_options?.usd.unit_amount;
  // }, [price]);

  // const priceValueString = React.useMemo(() => {
  //   return price
  //     ? "$" + price.unit_amount / 100 + " " + price.currency.toUpperCase()
  //     : undefined;
  // }, [price]);

  const isLoading = React.useMemo(() => isLoadingProduct, [isLoadingProduct]);

  // const { data: checkoutSession } = useQuery(
  //   ["stripe", "checkoutSession", price?.id],
  //   () => getCheckoutSession(price?.id ? price.id : ""),
  //   { enabled: price !== undefined }
  // );

  const [checkoutLoading, setCheckoutLoading] = React.useState<boolean>(false);

  const onClickCallback = React.useCallback(async () => {
    if (user) {
      setCheckoutLoading(true);
      const checkoutSession = await getCheckoutSession(
        price?.id ? price.id : ""
      );
      // window.location.href = `${checkoutSession.url}?prefilled_promo_code=BACK2SCHOOL`;
      window.location.href = `${checkoutSession.url}`;
    } else {
      navigate("/auth/signup");
    }
    setCheckoutLoading(false);
  }, [user, price]);

  return (
    <ProductCard
      title={"Premium"}
      isLoading={isLoading || checkoutLoading}
      heading={
        <View paddingTop={"small"}>
          <Grid templateColumns={"1fr auto 1fr"} gap={"xs"}>
            <Flex justifyContent={"end"}>
              <Text
                color={"teal.60"}
                alignSelf={"center"}
                fontFamily={"Poppins"}
                fontSize={"small"}
                textDecoration={"line-through"}
              >
                {interval === "month" ? "$8.99" : "$89.99"}
              </Text>
            </Flex>
            <Text fontWeight={"bold"} fontFamily={"Poppins"} fontSize={"large"}>
              {interval === "month" ? "$4.49 USD" : "$44.99 USD"}
            </Text>
          </Grid>
          <Grid templateColumns={"1fr auto 1fr"} gap={"xs"}>
            <Flex justifyContent={"end"}>
              <Text
                color={"teal.60"}
                alignSelf={"center"}
                fontFamily={"Poppins"}
                fontSize={"small"}
                textDecoration={"line-through"}
              >
                {interval === "month" ? "$11.99" : "$117.99"}
              </Text>
            </Flex>
            <Text
              fontWeight={"semibold"}
              fontFamily={"Poppins"}
              fontSize={"larger"}
            >
              {interval === "month" ? "$5.99 CAD" : "$58.99 CAD"}
            </Text>
          </Grid>
        </View>
      }
      subheading={
        <Text
          fontWeight={"semibold"}
          height={"60px"}
          fontSize={"small"}
          padding={"xs"}
          paddingBottom={"medium"}
          color={"teal.100"}
        >
          charged {interval}ly
          {interval === "year" ? (
            <Text
              as="span"
              fontWeight={"semibold"}
              color={"red.60"}
              fontFamily={"Playpen Sans"}
              fontSize={"small"}
            >
              {" "}
              (Save 18%)
            </Text>
          ) : null}
        </Text>
      }
      buttonText={
        isSubscribed ? (
          "Current Plan"
        ) : user?.customerTier === "Premium" ? (
          <Text color={"white"}>
            Current Plan{" "}
            <Text color={"white"} as="span" opacity={0.7} fontSize={"xs"}>
              (Trial)
            </Text>
          </Text>
        ) : (
          "Get Started"
        )
      }
      highlightColor={"teal.60"}
      onClick={onClickCallback}
      features={[
        "Everything in Starter",
        "Unlimited AI text generation tokens/month",
        "Smarter AI Model",
        "Translation capabilities",
        "Future LMS integrations",
        "Priority support",
        "Feature request priority",
        "Early access to major feature updates",
      ]}
    />
    // <RaisedCard padding={"0"} maxWidth={"500px"}>
    //   <Text
    //     borderRadius={"large large 0 0"}
    //     boxShadow={"0px -5px inset rgba(0,0,0,.2)"}
    //     fontWeight={"bold"}
    //     fontSize={"larger"}
    //     padding={"xs"}
    //     backgroundColor={highlightColor}
    //     color={"white"}
    //   >
    //     {productName}
    //   </Text>
    // {price ? (
    //   <>
    //     <Text
    //       fontWeight={"bold"}
    //       fontFamily={"Poppins"}
    //       fontSize={"larger"}
    //       paddingTop={"medium"}
    //     >
    //       {usdPrice ? `$${usdPrice / 100} USD` : ""}
    //     </Text>
    //     <Text
    //       fontWeight={"semibold"}
    //       fontFamily={"Poppins"}
    //       fontSize={"small"}
    //     >
    //       {cadPrice ? `$${cadPrice / 100} CAD` : ""}
    //     </Text>
    //   </>
    // ) : (
    //   <>
    //     <Text
    //       fontWeight={"bold"}
    //       fontFamily={"Poppins"}
    //       fontSize={"larger"}
    //       paddingTop={"medium"}
    //     >
    //       $8.99 USD
    //     </Text>
    //     <Text
    //       fontWeight={"semibold"}
    //       fontFamily={"Poppins"}
    //       fontSize={"small"}
    //     >
    //       $11.99 CAD
    //     </Text>
    //   </>
    // )}
    // <Text
    //   fontWeight={"semibold"}
    //   height={"60px"}
    //   fontSize={"small"}
    //   padding={"xs"}
    //   paddingBottom={"medium"}
    //   color={"teal.100"}
    // >
    //   charged {interval}ly
    //   {interval === "year" ? (
    //     <Text
    //       as="span"
    //       fontWeight={"semibold"}
    //       color={"red.60"}
    //       fontFamily={"Playpen Sans"}
    //       fontSize={"small"}
    //     >
    //       {" "}
    //       (Save 18%)
    //     </Text>
    //   ) : null}
    // </Text>
    //   <Flex justifyContent={"center"} padding={"xs xxl small xxl"}>
    //     <Button
    //       id="raised-btn"
    //       fontWeight={"semibold"}
    //       color="white"
    //       isFullWidth={true}
    //       backgroundColor={highlightColor}
    //       isDisabled={isLoading}
    //       onClick={onClickCallback}
    //     >
    //       {user?.customerTier !== undefined &&
    //       user.customerTier === product?.name
    //         ? "Current Plan"
    //         : "Upgrade"}
    //     </Button>
    //   </Flex>

    //   <Divider />

    //   <Grid
    //     templateColumns={"auto 1fr"}
    //     gap={"medium"}
    //     textAlign="left"
    //     padding={"medium"}
    //   >
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>Everything in Starter</Text>
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>
    //       Unlimited AI text generation tokens/month
    //     </Text>
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>Future LMS integrations</Text>
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>Priority support</Text>
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>Feature request priority</Text>
    //     <Text color={"green.60"}>
    //       <FaCheckCircle />
    //     </Text>
    //     <Text fontSize={"small"}>Early access to major feature updates</Text>
    //   </Grid>
    // </RaisedCard>
  );
}
