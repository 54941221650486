import React, { useContext, useMemo } from "react";
import useChatStream from "../../../../../../hooks/useChatStream";
import { Slide } from "../../../../types/slideTypes";
import { Lesson } from "../../../../types/lessonTypes";
import useUpdateEffect from "../../../../../../hooks/useUpdateEffect";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../../../../util/getTemplatedElements";
import { createSlideObject } from "../../../../util/createSlideObject";
import { LessonContext } from "../../../LessonContextProvider";

interface Props {
  useExistingTopic: boolean;
  useLessonPlan: boolean;
  topicLocal: string;
  lesson: Lesson;
  n?: number;
}

const useDiscussionQuestionSlideGenerator = (props: Props) => {
  const { useExistingTopic, lesson, topicLocal, useLessonPlan, n = 4 } = props;

  const [slides, setSlides] = React.useState<Slide[]>([]);
  const { outputLang } = useContext(LessonContext);

  // Define your state variables using useState
  const { connect, loading, output } = useChatStream({
    streamType: "list",
    type: "slide-discussion-questions",
    language: outputLang,
    inputObject: {
      topic: useExistingTopic ? lesson.topic : topicLocal,
      objectives: useLessonPlan ? lesson.objectives : "",
      gradeLevel: lesson.gradeLevel,
      readingLevel: lesson.readingLevel,
      n,
    },
    onOpen: () => {
      setSlides([]);
    },
    onClose: () => {},
    // onError: (error) => {

    // },
  });
  const generatedContent = useMemo(() => output, [output]);

  // useUpdateEffect(() => {

  // }, [output]);

  useUpdateEffect(() => {
    setSlides((prev) => {
      const slides = [...prev];
      const totalCurrentSlides = slides.length;
      const totalGenerated = generatedContent.length;

      for (let i = totalCurrentSlides; i < totalGenerated; i++) {
        const elements = getTemplatedElements(
          SlideElementsTemplate.DiscussionQuestion,
          {
            headerText: generatedContent[i],
          }
        );

        const slide: Slide = createSlideObject(lesson.id, elements);

        slides.push(slide);
      }
      return slides;
    });
  }, [generatedContent]);

  // Return the state variables and any functions that will update them
  return {
    connect,
    loading,
    slides,
  };
};

export default useDiscussionQuestionSlideGenerator;
