import { useCallback } from "react";
import useDebounce from "./useDebounce";
import { useOptimistic } from "./useOptimisticState";

type SetOptimisticState<T> = (
  value: T | ((prevState: T) => T),
  isOptimistic?: boolean
) => void;

export function useOptimisticDebounce<T>(
  fallbackState: T,
  debouceDelay: number,
  updateFunction: (value: T) => void | Promise<void>
): [T, SetOptimisticState<T>] {
  const [optimisticState, setOptimisticState] = useOptimistic<T>(fallbackState);


  const handleUpdate = useCallback(async (optimisticValue: T) => {
    await updateFunction(optimisticValue);
  }, [updateFunction]);

  useDebounce(
    async () => {

      await handleUpdate(optimisticState);
    },
    debouceDelay,
    [optimisticState]
  );

  return [optimisticState, setOptimisticState];
}
