export function isDeepEqual(objA: any, objB: any): boolean {
    // Check if both are primitives
    if (objA === objB) return true;

    // If either is null or not an object, return false
    if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) return false;

    let keysA = Object.keys(objA);
    let keysB = Object.keys(objB);

    // If number of properties is different, they're not equal
    if (keysA.length !== keysB.length) return false;

    // Check if the values of the property are the same, recursively
    for (let key of keysA) {
        if (!keysB.includes(key) || !isDeepEqual(objA[key], objB[key])) return false;
    }

    return true;
}