import * as React from "react";
import { View, Flex } from "@aws-amplify/ui-react";
import { Slide, TextElement } from "../../../types/slideTypes";
import { SlidesContext } from "../../SlidesContextProvider";
import SlideView from "../../editor/SlideView";
import AddIcon from "../../../../../components/icons/AddIcon";
import RaisedButton from "../../../../../components/RaisedButton";
import { useAutoPickImageMutation } from "../../../../../hooks/useAutoPickImageMutation";
import { S3ObjectProtected } from "../../../../../API";
import { LessonContext } from "../../LessonContextProvider";

export interface IGeneratedSlidePreviewProps {
  slide: Slide;
  // setSlideCurrent: React.Dispatch<React.SetStateAction<Slide | undefined>>;
  addSlide: (slide: Slide) => void;
  loading: boolean;
  updateSlide?: (slide: Slide) => void;
}

export default function GeneratedSlidePreview(
  props: IGeneratedSlidePreviewProps
) {
  const { slide, addSlide, loading, updateSlide } = props;

  const { slidePreview, setSlidePreview } = React.useContext(SlidesContext);

  const selected = React.useMemo(
    () => slidePreview?.id === slide?.id,
    [slidePreview, slide]
  );

  const { lesson } = React.useContext(LessonContext);

  const text = React.useMemo(() => {
    const textElements = slide.elements.filter(
      (element) => element.elementType === "text"
    );
    return (textElements as TextElement[])
      .map((element) => element.props.ops.map((op) => op.insert).join(" "))
      .join(" ");
  }, [slide]);

  const updateSlideImage = React.useCallback(
    (image: S3ObjectProtected, url: string) => {

      if (updateSlide) {
        updateSlide({
          ...slide,
          elements: slide.elements.map((element) => {
            if (element.elementType === "image") {
              const newElement = { ...element };
              newElement.s3Item.key = image.key;
              newElement.s3Item.identityId = image.identityId;
              newElement.s3Item.level = "protected";
              newElement.props.path = url;

              return newElement;
            }
            return element;
          }),
        });
      }
    },
    [slide]
  );

  const { autoPickImage, isLoading } = useAutoPickImageMutation();

  React.useEffect(() => {

    if (text)
      autoPickImage({
        prompt: text,
        onUpload: (image, url) => updateSlideImage(image, url),
      });
  }, [text]);

  return (
    <View
      position={"relative"}
      opacity={selected ? 1 : 0}
      width={"100%"}
      height={"100%"}
      borderRadius={"small"}
      onMouseEnter={() => {
        if (!loading) setSlidePreview(slide);
      }}
      onMouseLeave={() => {
        if (!loading) setSlidePreview(undefined);
      }}
      onClick={() => {
        if (!loading) addSlide(slide);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <SlideView
        isStatic
        isClickable={false}
        isImageLoading={isLoading}
        slide={slide}
        idPrefix={"preview-"}
      />
      {selected && (
        <Flex
          position={"absolute"}
          top={"0"}
          left={"0"}
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <RaisedButton
            className="bouncy"
            gap={"small"}
            size="small"
            backgroundColor={selected ? "orange.60" : "white"}
            fontWeight={"bold"}
            color={selected ? "white" : "black"}
            borderRadius={"small"}
            data-attr="add-lesson-slides"
            data-ph-capture-attribute-type={"add-individual-slide"}
            data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
            data-ph-capture-attribute-grade-level={lesson.gradeLevel}
            data-ph-capture-attribute-reading-level={lesson.readingLevel}
            data-ph-capture-attribute-language={lesson.lang}
          >
            Add <AddIcon />
          </RaisedButton>
        </Flex>
      )}
    </View>
  );
}
