import { useState, useCallback } from 'react';

type SetOptimisticState<T> = (value: T | ((prevState: T) => T), isOptimistic?: boolean) => void;

export function useOptimistic<T>(fallbackState: T): [T, SetOptimisticState<T>] {
  const [realState, setRealState] = useState<T>(fallbackState);
  const [optimisticState, setOptimisticStateInternal] = useState<T>(fallbackState);

  const setOptimisticState: SetOptimisticState<T> = useCallback((value, isOptimistic = false) => {
    // Update the state based on whether it's an optimistic update or not

    const updater = (typeof value === 'function') ? value as (prevState: T) => T : () => value;
    if (isOptimistic) {      

      setOptimisticStateInternal(prevState => updater(prevState));
    } else {

      setRealState(prevState => updater(prevState));
      setOptimisticStateInternal(prevState => updater(prevState));
    }
  }, [realState, optimisticState]);

  return [optimisticState, setOptimisticState];
}
