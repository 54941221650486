import * as React from "react";
import { UpdateUserObject, User } from "../types/User";
import useModal from "../hooks/useModal";
import useUserQuery from "../hooks/useUserQuery";
import { useCookieConsent } from "../hooks/useCookieConsent";
import ReactGA from "react-ga4";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../util/User/updateUser";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { toast } from "react-toastify";
import {
  PremiumFeatureAlert,
  OutOfTokensAlert,
} from "../features/premium/components";
import { useMemo } from "react";
import { listStripeSubscriptions } from "../features/premium";

interface IUserContext {
  userId: string | undefined;
  user: User | undefined;
  userRefetch: () => void;
  authenticated: boolean | undefined;
  tokens: number;
  baseTokens: number;
  bonusTokens: number;
  hasTokens: () => boolean;
  isPremium: () => boolean;
  isSubscribed: boolean;
  updateUser: (updateObject: UpdateUserObject) => Promise<any>;
}

const UserContext = React.createContext({} as IUserContext);

export function useUserContext() {
  return React.useContext(UserContext);
}

export interface IUserContextProviderProps {
  children: React.ReactNode;
}

export default function UserContextProvider(props: IUserContextProviderProps) {
  const {
    user,
    authenticated,
    refetch: userRefetch,
    identityId,
  } = useUserQuery();

  const { cookieConsent } = useCookieConsent();
  const queryClient = useQueryClient();
  const { user: authUser } = useAuthenticator((context) => [context.user]);

  const { data: subscriptions } = useQuery({
    queryKey: ["subscription", "list", user?.customerId],
    queryFn: async () => {
      if (user === undefined || user.customerId === undefined) return [];
      return await listStripeSubscriptions(user.customerId);
    },
    enabled: !!user,
  });

  const isSubscribed = useMemo(() => {
    if (subscriptions === undefined) return false;
    return subscriptions.length > 0;
  }, [subscriptions]);

  React.useEffect(() => {
    if (cookieConsent || authenticated) {
      ReactGA.initialize("G-LT8GNF036J");

      posthog.init("phc_eOeDyxZwX8JAdZZjITUrOuTyY4Uo7ez2BmJpEoDSuyB", {
        api_host: "https://app.posthog.com",
      });
    }
  }, [cookieConsent, authenticated]);

  const baseTokens = React.useMemo(
    () => (user ? user?.tokensBase : 0),
    [user?.tokensBase]
  );
  const bonusTokens = React.useMemo(
    () => (user ? user?.tokensBonus : 0),
    [user?.tokensBonus]
  );

  const tokens = React.useMemo(
    () => (user ? user?.tokensBase + user?.tokensBonus : 0),
    [user?.tokensBase, user?.tokensBonus]
  );

  const [OutOfTokensModal, setOutOfTokensModal] = useModal(
    {
      title: "Oops! You're Out of Tokens!",
      ReactComponent: () => (
        <OutOfTokensAlert onComplete={() => setOutOfTokensModal(false)} />
      ),
      size: "lg",
    },
    []
  );
  const [PremiumFeatureModal, setPremiumFeatureModal] = useModal(
    {
      title: "Unlock Gibbly Premium!",
      ReactComponent: () => (
        <PremiumFeatureAlert onComplete={() => setPremiumFeatureModal(false)} />
      ),
      size: "lg",
    },
    []
  );

  const hasTokens = React.useCallback(() => {
    if (user?.customerTier === "Premium") {
      return true;
    } else if (tokens > 0) {
      return true;
    } else {
      setOutOfTokensModal(true);
      return false;
    }
  }, [tokens, user]);

  const isPremium = React.useCallback(() => {
    if (user?.customerTier === "Premium") {
      return true;
    } else {
      setPremiumFeatureModal(true);
      return false;
    }
  }, [user]);

  const { mutateAsync: updateUser_mutateAsync } = useMutation({
    mutationFn: (updateObject: UpdateUserObject) => {
      if (!user) throw new Error("User not found");
      const { creatorId } = user;
      return updateUser(creatorId, updateObject);
    },
    onMutate: async (updateObject: UpdateUserObject) => {
      const previousUser = user;
      queryClient.setQueryData(["user", authUser.username], () => {
        return { ...previousUser, ...updateObject };
      });
      return { previousUser };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
    },
    onError: () => {
      toast.error("Error updating user. Please try again.");
      queryClient.invalidateQueries(["user"]);
    },
  });

  return (
    <>
      <OutOfTokensModal />
      <PremiumFeatureModal />

      <PostHogProvider client={posthog}>
        <UserContext.Provider
          value={{
            userId: identityId,
            user: user,
            userRefetch,
            authenticated,
            tokens,
            baseTokens,
            bonusTokens,
            hasTokens,
            isPremium,
            isSubscribed,
            updateUser: updateUser_mutateAsync,
          }}
        >
          {props.children}
        </UserContext.Provider>
      </PostHogProvider>
    </>
  );
}
