import * as React from "react";
import { SlidesContext } from "../SlidesContextProvider";
import ColorPalette from "../editor/ColorPalette";
import { Flex } from "@aws-amplify/ui-react";

export interface IBackgroundMenuProps { }

// const colors = [
//   "#FFFFFF",
//   "#d9d9d9",
//   "#808080",
//   // "#000000", //black
//   "#d4f2bf",
//   "#a8e67f",
//   "#6ed52a",
//   // "#2c5511", // dark green (90)
//   "#ffb3d4",
//   "#ff66a8",
//   "#ff006f",
//   // "#66002c", // dark pink (90)

//   "#deb3ff",
//   "#bd66ff",
//   "#9100ff",
//   // "#3a0066", //dark purple (90)
//   "#f5c3bc",
//   "#ee9b90",
//   "#de3721",
//   // "#59160d", //dark red (90)
//   "#ffebb3",
//   "#ffdd80",
//   "#ffbb00",
//   // "#664b00",  //dark yellow (90)
//   "#f5d9bc",
//   "#efbf8f",
//   "#ff7b00",
//   // "#663100", //dark orange (90)
//   "#75bcff",
//   "#47a6ff",
//   "#1a90ff",
//   //  "#105698", //dark teal (90)
//   "#c9cee9",
//   "#929cd3",
//   "#4a5ab5",
//   // "#1e2448", //dark blue (90)

//   // Add more hex codes here
// ];

export default function BackgroundMenu(props: IBackgroundMenuProps) {
  const { } = props;

  const { setBackgroundColor, slideCurrent } = React.useContext(SlidesContext);

  return (

    <Flex justifyContent={"center"}>

      <ColorPalette
        // palette={[[undefined, "#FFF", "#CCC"]]}
        buttonSize="40px"
        color={slideCurrent.background.color}
        onSelectColor={setBackgroundColor} />
    </Flex>


    // <Grid templateColumns={"repeat(auto-fill, minmax(30px, 1fr) )"} gap={"xs"}>
    //   {colors.map((color, i) => (
    //     <Card
    //       key={i}
    //       variation="outlined"
    //       backgroundColor={color}
    //       style={{ aspectRatio: 1, outlineColor: "black", borderWidth: '2px' }}
    //       onClick={() => {

    //         setBackgroundColor(color);
    //       }}
    //     >
    //     </Card>
    //   ))}
    // </Grid>
  );
}
