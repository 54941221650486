import {
  Button,
  ButtonGroup,
  Card,
  CheckboxField,
  Flex,
  ResponsiveStyle,
  StyleToken,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useCallback, useMemo } from "react";
import getTimeSince from "../../../../util/timeSinceUpdated";

import { Lesson } from "../../../lesson/types/lessonTypes";
import { S3Image } from "../../../../components/S3Image";
import { SpaceKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";
import useAsync from "../../../../hooks/useAsync";
import { querySlides } from "../../../lesson/services/querySlides";
import RaisedCard from "../../../../components/RaisedCard";
import { deleteLesson } from "../../../lesson/services/deleteLesson";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { getIdentityId } from "../../../game/util";
import { ImageComponent } from "../../../../components/ImageComponent";
import { S3ObjectProtected } from "../../../../API";
import { TooltipView } from "../../../../components/TooltipView";
import KebabIcon from "../../../../components/icons/KebabIcon";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";
import { duplicateLesson } from "../../../lesson/services/duplicateLesson";

//const quizthumbnail = require("../../../assets/default-quiz-img.png");

interface ILibraryLessonCardProps {
  lesson: Lesson;
  width:
    | ResponsiveStyle<SpaceKeys<StyleToken<Property.Width<0 | (string & {})>>>>
    | undefined;
  hasButtons: boolean;
  onClick?: (id: string) => void;
  selectable?: boolean;
  onSelect?: (id: string) => void;
  onDeselect?: (id: string) => void;
  selected?: boolean;
  onChange?: () => void;
}

export default function LibraryLessonCard({
  lesson,
  width,
  hasButtons,
  onClick,
  selectable,
  onSelect,
  onDeselect,
  selected,
  onChange,
}: ILibraryLessonCardProps): JSX.Element {
  const [isDeleting, setIsDeleting] = React.useState(false);

  const [DeleteModal, setShowDeleteModal] = useConfirmationModal({
    confirmFn: async () => await handleDelete(),
    headerMessage: "Delete Lesson",
  });

  const id = useMemo(() => lesson.id, [lesson]);

  const { title, updatedAt } = lesson;

  const slidesAsync = useAsync(async () => await querySlides(id), [id]);

  const slides = useMemo(() => slidesAsync.value, [slidesAsync.value]);

  const { value: isMine } = useAsync(async () => {
    const identityId = await getIdentityId();
    return identityId === lesson.userId;
  }, [lesson.userId]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    await deleteLesson(id);
    await onChange?.();
    setIsDeleting(false);
  }, [id]);

  // boolean that determines if the image is legacy or not
  // if the image is legacy, we have to parts the image key that is in the format "protected/identityId/imageKey"
  const ifLegacyImage = React.useMemo(() => !!lesson.image.bucket, []);

  const [showActions, setShowActions] = React.useState(false);
  const [duping, setDuping] = React.useState(false);

  const tooltipRef = useClickOutsideDetection<HTMLDivElement>(
    (e) => {
      if (tooltipRef.current?.contains(e.target as Node)) return;
      if (showActions) {
        setShowActions(false);
      }
    },
    [showActions]
  );

  return (
    <>
      <DeleteModal />
      <RaisedCard
        opacity={isDeleting ? "50%" : "100%"}
        width={width}
        padding={"0"}
        position={"relative"}
        id="raised-btn"
        style={{ cursor: "pointer" }}
      >
        <Flex
          direction={"column"}
          grow={1}
          justifyContent={"end"}
          minHeight={"100%"}
          onClick={() => {
            onClick?.(id);
          }}
        >
          <Flex
            flex={1}
            grow={1}
            direction={"column"}
            style={{ position: "relative" }}
          >
            {ifLegacyImage ? (
              <S3Image
                bucket={lesson.image.bucket}
                s3key={lesson.image.key}
                alt={lesson.title + " Cover Image"}
                objectFit="cover"
                objectPosition="50% 50%"
                backgroundColor="initial"
                height={"200px"}
                opacity="100%"
                borderRadius={"small small 0 0"}
              />
            ) : (
              <ImageComponent
                alt={undefined}
                autoPickPrompt={lesson.title}
                height={"200px"}
                objectFit="cover"
                canEdit={false}
                image={
                  {
                    key: lesson.image.key,
                    identityId: lesson.image.identityId,
                  } as S3ObjectProtected
                }
              />
            )}
            <View position={"absolute"} top={"5px"} right={"5px"}>
              <TooltipView
                show={showActions}
                placement="left-start"
                tooltipChildren={
                  <ButtonGroup
                    ref={tooltipRef}
                    direction={"column"}
                    // alignItems={"start"}
                    gap={"0"}
                  >
                    <Button
                      justifyContent={"start"}
                      variation="menu"
                      size="small"
                      color={"black"}
                      backgroundColor={"transparent"}
                      isLoading={duping}
                      loadingText="Making a copy..."
                      data-attr="make-a-copy"
                      data-ph-capture-attribute-type={"lesson"}
                      data-ph-capture-attribute-owner={lesson.userId}
                      data-ph-capture-attribute-id={id}
                      data-ph-capture-attribute-title={title}
                      data-ph-capture-attribute-n-slides={slides?.length}
                      onClick={async (e) => {
                        e.stopPropagation();
                        setDuping(true);

                        const newLesson = await duplicateLesson(lesson);
                        window.open(
                          `/lesson/${newLesson.userId}/${newLesson.id}`,
                          "_blank"
                        );
                        onChange?.();
                        setDuping(false);
                        setShowActions(false);
                      }}
                    >
                      Make a copy
                    </Button>
                    {hasButtons && isMine && (
                      <Button
                        justifyContent={"start"}
                        variation="destructive"
                        size="small"
                        color={"red.80"}
                        backgroundColor={"transparent"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </ButtonGroup>
                }
              >
                <Button
                  border={"none"}
                  borderRadius={"5rem"}
                  padding={"xxs"}
                  backgroundColor={"overlay.70"}
                  color={"white"}
                  position={"relative"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowActions(!showActions);
                  }}
                >
                  <KebabIcon />
                </Button>
              </TooltipView>
            </View>
            <Card
              width="60%"
              borderRadius={"5rem"}
              padding={"xxxs"}
              marginTop={"zero"}
              backgroundColor={"overlay.70"}
              textAlign={"center"}
              fontSize={"smaller"}
              fontWeight={"semibold"}
              color={"white"}
              style={{ position: "absolute", bottom: "5px", right: "5px" }}
            >
              {slides ? slides.length : "?"}{" "}
              {slides?.length === 1 ? "Slide" : "Slides"}
            </Card>
            <Card
              borderRadius={"5rem"}
              padding={"0"}
              marginTop={"zero"}
              backgroundColor={"overlay.70"}
              textAlign={"center"}
              fontSize={"xs"}
              fontWeight={"bold"}
              color={"white"}
              style={{ position: "absolute", bottom: "5px", left: "5px" }}
            ></Card>
          </Flex>
          <Flex
            padding={"xxxs"}
            paddingLeft={"small"}
            paddingRight={"small"}
            paddingBottom={"small"}
            direction={"column"}
            gap={"xxxs"}
          >
            <Text fontSize={"medium"} fontWeight={"bold"} isTruncated>
              {title === "" ? "untitled" : title}
            </Text>
            {/* <Text padding={'0'} fontSize={'xs'} fontWeight={'bold'} as='span'>0 plays</Text> */}
            <Text padding={"0"} fontSize={"xs"}>
              Updated {getTimeSince(updatedAt)}
            </Text>
          </Flex>
        </Flex>
        {selectable && (
          <CheckboxField
            backgroundColor={"white"}
            name={"index"}
            value={"yes"}
            label={"Selected"}
            labelHidden
            position={"absolute"}
            size={"large"}
            top={"15px"}
            left={"15px"}
            checked={selected}
            onChange={(e) => {
              if (e.target.checked) onSelect?.(id);
              else onDeselect?.(id);
            }}
          />
        )}
        {/* <DeleteModal /> */}
      </RaisedCard>
    </>
  );
}
