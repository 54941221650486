import { Image, ImageProps } from "@aws-amplify/ui-react";
import * as React from "react";
import useAsync from "../hooks/useAsync";
import { getS3Object } from "../services/awsFacade";

export interface IS3ImageProps extends Omit<ImageProps, "src"> {
  bucket?: string;
  s3key?: string;
}

export function S3Image(props: IS3ImageProps) {
  const { bucket, s3key, ...restProps } = props;

  const url = useAsync(async () => {
    if (!bucket || !s3key) return undefined;
    return await getS3Object(bucket, s3key);
  }, [bucket, s3key]);

  return (
    <Image
      {...restProps}
      loading="lazy"
      src={url.value}
      style={{
        ...restProps.style,
        opacity: url.initializing ? 0 : 1,
        transition: "opacity 0.2s",
      }}
    />
  );
}
