/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "GameAPI",
            "endpoint": "https://ekcz5h7163.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qo3kbo42cre6jd4ickbm3qnu2y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-e6miyhdv3fgabnuosnablvibfu",
    "aws_cognito_identity_pool_id": "us-east-1:efe4438a-eb54-44b7-98b6-95c6bf205979",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SKljaUZA0",
    "aws_user_pools_web_client_id": "2i7hd978d7v8i3hcm9iag94rfi",
    "oauth": {
        "domain": "gibbly-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.gibbly.co/dashboard/discover/",
        "redirectSignOut": "https://www.gibbly.co/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Assignment-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "AssignmentResult-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "ChatConnection-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Classroom-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "CompleteGame-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "FreeTrial-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "GameSession-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Generation-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Lesson-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "LessonLink-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Lobby-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "LobbyConnection-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Player-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "PlayerProgress-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "PlayerSession-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Referral-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Slide-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Student-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "Team-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "User-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "media-bucket112825-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
