import * as React from "react";
import { TooltipView } from "../../../../components/TooltipView";
import {
  SlideElement,
  ImageElement,
  TextElement,
} from "../../types/slideTypes";
import { SlideElementsContext } from "../SlideElementsContextProvider";
import {
  Button,
  Flex,
  Image as Img,
  Placeholder,
  View,
} from "@aws-amplify/ui-react";
import EditIcon from "../../../../components/icons/EditIcon";
import useModal from "../../../../hooks/useModal";
import ImageGallery from "../../../quiz/components/ImageGallery";
import { S3ObjectProtected } from "../../../../API";
import { SlidesContext } from "../SlidesContextProvider";
import GalleryIcon from "../../../../components/icons/GalleryIcon";
import SparkleIcon from "../../../../components/icons/SparkleIcon";
import { useAutoPickImageMutation } from "../../../../hooks/useAutoPickImageMutation";
import { LessonContext } from "../LessonContextProvider";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";
import { getS3Url } from "../../../../services/s3/getS3Url";

export interface IImageElementProps {
  slideElement: SlideElement;
}

export default function ImageElementView(props: IImageElementProps) {
  const { slideElement } = props;

  const { lesson } = React.useContext(LessonContext);
  const {
    selectedElements,
    onChangeElement,
    isStatic,
    isImageLoading,
    selectElement,
    removeSelectedElement,
  } = React.useContext(SlideElementsContext);

  const { slideIndex, slideCurrent } = React.useContext(SlidesContext);

  const text = React.useMemo(() => {
    const textElements = slideCurrent.elements.filter(
      (element) => element.elementType === "text"
    );
    return (textElements as TextElement[])
      .map((element) => element.props.ops.map((op) => op.insert).join(" "))
      .join(" ")
      .trim();
  }, [slideCurrent]);

  const imageRef = useClickOutsideDetection(() => {
    removeSelectedElement(slideElement.id);
  }, [slideElement.id]);

  const quickPicPrompt = React.useMemo(() => {
    return text !== "" ? text : lesson.topic;
  }, [text, lesson.topic]);

  const [error, setError] = React.useState(false);

  const selected = React.useMemo(
    () => selectedElements.includes(slideElement.id),
    [slideElement, selectedElements]
  );

  const imageElement = React.useMemo(() => {
    // if (isStatic)

    return slideElement as ImageElement;
  }, [slideElement]);

  const objectFit = React.useMemo(() => {
    if (imageElement.props.sizing?.type === "contain") return "contain";
    if (imageElement.props.sizing?.type === "cover") return "cover";
    return "fill";
  }, [imageElement.props.sizing?.type]);

  React.useEffect(() => {
    // if (!isStatic)
    reloadImage();
  }, [slideIndex]);

  const handleImageUpload = React.useCallback(
    (image: S3ObjectProtected, url: string) => {
      if (isStatic) return;
      if (slideElement.elementType !== "image") return;

      // const { width = 500, height = 500 } = dimensions ?? {};

      const element = { ...slideElement };

      if (element.elementType !== "image") return;
      element.s3Item.key = image.key;
      element.s3Item.identityId = image.identityId;
      element.s3Item.level = "protected";
      element.props.path = url;
      // element.props.w = width;
      // element.props.h = height;

      onChangeElement(element);

      setImageGalleryModal(false);
    },
    [slideElement, onChangeElement]
  );

  React.useEffect(() => {
    setError(false);
  }, [imageElement.props.path]);

  const updateImageDimensionsCallback = React.useCallback((): Promise<{
    width: number;
    height: number;
  }> => {
    return new Promise((resolve, reject) => {
      if (!imageElement.props.path) return reject("No image path provided");
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        const element = { ...slideElement };
        element.props.w = width;
        element.props.h = height;

        onChangeElement(element);
        resolve({ width, height });
      };
      img.onerror = (error) =>
        reject(
          `Error loading image path ${imageElement.props.path}, Error: ${error}`
        );
      img.src = imageElement.props.path ?? "";
    });
  }, [imageElement.props.path, onChangeElement, slideElement]);

  React.useEffect(() => {
    updateImageDimensionsCallback().catch(() => {

    });
  }, [imageElement.props.path]);

  const handleError = async () =>
    // e: React.SyntheticEvent<HTMLImageElement, Event>
    {
      setError(true);
      // const imgElement = e.currentTarget;
      // const src = await getUrl("default-slide-img.png", { level: "public" });
      // imgElement.src = src;
    };

  const reloadImage = React.useCallback(async () => {
    const { key, identityId, level } = imageElement.s3Item;
    if (!key) return;
    getS3Url({ version: 2, path: `${level}/${identityId}/${key}` }).then(
      ({ url }) => {

        const newSlideElement: SlideElement = { ...slideElement };
        if (newSlideElement.elementType !== "image") return;
        newSlideElement.props.path = url.toString();
        onChangeElement(newSlideElement);
      }
    );
  }, [slideElement, imageElement]);

  const [ImageGalleryModal, setImageGalleryModal] = useModal(
    {
      ReactComponent: () => (
        <ImageGallery onUpload={handleImageUpload} text={quickPicPrompt} />
      ),
      size: "lg",
    },
    [text, lesson.topic]
  );

  const { autoPickImage, isLoading } = useAutoPickImageMutation();

  return (
    <>
      <ImageGalleryModal />
      <TooltipView
        tooltipProps={{ style: { zIndex: 999 } }}
        show={!isStatic && (selected || imageElement.props.path === "")}
        tooltipChildren={
          <Flex gap={"xs"}>
            <Button
              backgroundColor={"#9100ff"}
              color={"white"}
              isLoading={isLoading}
              onClick={() =>
                autoPickImage({
                  prompt: quickPicPrompt,
                  onUpload: handleImageUpload,
                })
              }
            >
              <SparkleIcon />
            </Button>
            <Button color={"white"} backgroundColor={"#47a6ff"} onClick={() => setImageGalleryModal(true)}>
              <EditIcon />
            </Button>
          </Flex>
        }
      >
        <View
          ref={imageRef}
          onClick={() => {
            selectElement(slideElement.id);
          }}
        >
          {isLoading || isImageLoading ? (
            <Placeholder width={"100%"} height={"100%"} position={"absolute"} />
          ) : !error ? (
            <Img
              src={imageElement.props.path}
              alt={undefined}
              onError={handleError}
              objectFit={objectFit}
              position={"absolute"}
              loading="eager"
              width={"100%"}
              height={"100%"}
            />
          ) : isStatic ? null : (
            <Flex
              position={"absolute"}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"lightgray"}
              width={"100%"}
              height={"100%"}
            >
              <GalleryIcon width={"40%"} height={"40%"} color={"white"} />
            </Flex>
          )}
        </View>
      </TooltipView>
    </>
  );
}

// I am building a presentation editor with very complex state management. I'm having issues with managing array state. For example, a presentation has an array of slides, which each have an array of elements.

// When an element is edited, it triggers a state change for all the elements of slides, and hence all the elements.

// How can i manage this state better?
