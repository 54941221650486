import * as React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useEffectOnce from "../hooks/useEffectOnce";

interface IVolumeContext {
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  multiplier: number;
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
}

const VolumeContext = React.createContext<IVolumeContext>({
  volume: 0,
  setVolume: () => {},
  multiplier: 1,
  setMultiplier: () => {},
});

export function useVolumeContext() {
  return React.useContext(VolumeContext);
}

export interface IVolumeContextProviderProps {
  children: React.ReactNode;
}

export default function VolumeContextProvider(
  props: IVolumeContextProviderProps
) {
  const [volume, setVolume] = useLocalStorage<number>("audio-volume", 0.1);
  const [multiplier, setMultiplier] = React.useState<number>(1);

  useEffectOnce(() => {
    if (volume > 0.3) setVolume(0.3);
  });

  return (
    <>
      <VolumeContext.Provider
        value={{
          volume,
          setVolume,
          multiplier,
          setMultiplier,
        }}
      >
        {props.children}
      </VolumeContext.Provider>
    </>
  );
}
