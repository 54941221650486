import {
  Flex,
  Heading,
  Image,
  Loader,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
} from "@aws-amplify/ui-react";
import * as React from "react";
import EditableText from "../components/EditableText";
import { useUserContext } from "../context/UserContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import validateUsername from "../util/validateUsername";
import getCreatorId from "../util/GetCreatorId";
import { PutUserInput } from "../types/User";
import RestAPI from "../util/RestAPI";
import { toast } from "react-toastify";
import RaisedCard from "../components/RaisedCard";
import InfoIcon from "../components/icons/InfoIcon";
import BillingPortalButton from "../features/stripe/components/BillingPortalButton";
import RaisedButton from "../components/RaisedButton";
import { useNavigate } from "react-router";
import LanguageSelect from "../components/LanguageSelect";
import ModelToggle from "../features/generate/components/ModelToggle";

export interface IUserDetailsProps {}

export default function UserDetails(props: IUserDetailsProps) {
  const {} = props;
  const navigate = useNavigate();
  const { user, userRefetch, baseTokens, bonusTokens } = useUserContext();

  const updateUsername = useMutation({
    mutationFn: async (username: string) => {
      await validateUsername(username, {
        onSuccess: async () => {
          const creatorId = await getCreatorId();

          if (creatorId === undefined) {
            throw new Error("creatorId is undefined");
          }
          const updateUserInput: PutUserInput = {
            body: {
              Key: { creatorId },
              ReturnValues: "ALL_NEW",
              UpdateExpression: "SET username = :username",
              ExpressionAttributeValues: { ":username": username },
            },
          };
          const result = await RestAPI.put(`/users`, updateUserInput);

          userRefetch();
          return result;
        },
        onError: (e) => {
          toast.error(e.message);
        },
      });
    },
    onSuccess: () => {},
  });

  const updateRole = useMutation({
    mutationFn: async (userType: string) => {
      const creatorId = await getCreatorId();

      if (creatorId === undefined) {
        throw new Error("creatorId is undefined");
      }
      const updateUserInput: PutUserInput = {
        body: {
          Key: { creatorId },
          ReturnValues: "ALL_NEW",
          UpdateExpression: "SET userType = :userType",
          ExpressionAttributeValues: { ":userType": userType },
        },
      };
      const result = await RestAPI.put(`/users`, updateUserInput);
      userRefetch();
      return result;
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: changeLanguageMutation } = useMutation({
    mutationFn: async (lang: string) => {
      const creatorId = user?.creatorId;

      if (creatorId === undefined) {
        throw new Error("creatorId is undefined");
      }
      const updateUserInput: PutUserInput = {
        body: {
          Key: { creatorId },
          ReturnValues: "ALL_NEW",
          UpdateExpression: "SET lang = :lang",
          ExpressionAttributeValues: { ":lang": lang },
        },
      };
      const result = await RestAPI.put(`/users`, updateUserInput);
      userRefetch();
      return result;
    },
    onMutate: (lang: string) => {
      queryClient.setQueryData(["user"], (oldData: any) => {
        return { ...oldData, lang };
      });
    },
  });

  return (
    <RaisedCard>
      <Heading id="sectionTitle">Account Information</Heading>

      <Table title="Account Information" size={"small"}>
        <TableBody>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Username:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              {user?.username ? (
                <EditableText
                  value={user.username}
                  onSubmit={async (value) => {
                    if (value !== user?.username) {
                      await updateUsername.mutateAsync(value);
                    }
                  }}
                />
              ) : (
                <Loader />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Role:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              {user ? (
                <SelectField
                  size={"small"}
                  fontFamily={"Poppins"}
                  fontSize={"small"}
                  labelHidden
                  label={"Role"}
                  variation="quiet"
                  border={"none"}
                  value={user.userType}
                  onChange={(e) => updateRole.mutate(e.target.value)}
                >
                  <option value="STUDENT">Student</option>
                  <option value="TEACHER">Teacher</option>
                  <option value="PROFESSIONAL">Professional</option>
                  <option value="PERSONAL">Personal</option>
                </SelectField>
              ) : (
                <Loader />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Output Language:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              {user ? (
                <LanguageSelect
                  size={"small"}
                  fontFamily={"Poppins"}
                  fontSize={"small"}
                  labelHidden
                  variation="quiet"
                  border={"none"}
                  value={user?.lang}
                  // disableList={[]}
                  onChange={(lang) => changeLanguageMutation(lang)}
                ></LanguageSelect>
              ) : (
                // <SelectField
                //   size={"small"}
                //   fontFamily={"Poppins"}
                //   fontSize={"small"}
                //   labelHidden
                //   label={"Role"}
                //   variation="quiet"
                //   border={"none"}
                //   width={"130px"}
                //   value={user?.lang || "english"}
                //   onChange={(e) => changeLanguageMutation(e.target.value)}
                // >
                //   <option value="en">English</option>
                //   <option value="fr">Français</option>
                // </SelectField>
                <Loader />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Preferred Model:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              <ModelToggle width={"100%"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Date Joined:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              {user ? (
                <Text
                  padding={"xxs"}
                  fontWeight={"normal"}
                  fontFamily={"Poppins"}
                  fontSize={"small"}
                  color={"black"}
                >
                  {new Date(user.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Text>
              ) : (
                <Loader />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Plan:
              </Text>
            </TableCell>

            <TableCell colSpan={2}>
              {user ? (
                <Flex
                  justifyContent={"space-between"}
                  gap={"xs"}
                  wrap={"wrap"}
                  alignItems={"center"}
                >
                  <Text
                    padding={"xxs"}
                    fontWeight={"normal"}
                    fontFamily={"Poppins"}
                    fontSize={"small"}
                    color={"black"}
                  >
                    {user.customerTier ? user.customerTier : "Starter"}
                    {user.customerTier === "Premium" ? (
                      <Image
                        src="/images/icons/crown.png"
                        alt="A gold crown"
                        style={{ marginLeft: "6px" }}
                        objectFit={"contain"}
                        height={"medium"}
                      />
                    ) : null}
                  </Text>
                  <Flex wrap={"wrap"} gap={"xs"}>
                    <RaisedButton
                      gap={"xs"}
                      size="small"
                      fontSize={"smaller"}
                      id="raised-btn"
                      padding={"xs"}
                      variation="primary"
                      onClick={() => navigate("/dashboard/upgrade")}
                    >
                      <Image
                        src="/images/icons/crown.png"
                        alt="A gold crown"
                        style={{ marginLeft: "10px" }}
                        objectFit={"contain"}
                        height={"medium"}
                      />
                      Upgrade
                    </RaisedButton>

                    <BillingPortalButton />
                  </Flex>
                </Flex>
              ) : (
                <Loader />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">
              <Text fontWeight={"semibold"} fontFamily={"Poppins"}>
                Tokens:
              </Text>
            </TableCell>
            <TableCell>
              <Flex rowGap={"xs"} columnGap={"medium"} wrap={"wrap"}>
                {baseTokens !== undefined ? (
                  <Flex alignItems={"center"}>
                    <Text
                      padding={"xxs"}
                      fontWeight={"normal"}
                      fontFamily={"Poppins"}
                      fontSize={"small"}
                      color={"teal.90"}
                    >
                      <b>Base:</b>{" "}
                      {baseTokens ? baseTokens.toLocaleString() : 0}
                    </Text>
                    <InfoIcon message="Your AI tokens reset each month." />
                  </Flex>
                ) : (
                  <Loader />
                )}

                {bonusTokens !== undefined ? (
                  <Flex alignItems={"center"}>
                    <Text
                      padding={"xxs"}
                      fontWeight={"normal"}
                      fontFamily={"Poppins"}
                      fontSize={"small"}
                      color={"purple.80"}
                    >
                      <b>Bonus:</b>{" "}
                      {bonusTokens ? bonusTokens.toLocaleString() : 0}
                    </Text>
                    <InfoIcon message="Refer friends to earn more!" />
                  </Flex>
                ) : (
                  <Loader />
                )}
              </Flex>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <Text as='p' lineHeight={'xxs'} fontWeight={'semibold'}>Email:<Button padding={'xxs'} gap={'medium'} fontWeight={'normal'} variation='link' color={'black'} >{auth.user.attributes.email}<EditIcon /></Button></Text> */}

      {/* <Text as='p' fontWeight={'semibold'}>School:<Button padding={'xxs'} gap={'medium'} fontWeight={'normal'} variation='link' color={'black'} >None or School's Name<EditIcon /></Button></Text> */}

      {/* <Text as='p' fontWeight={'semibold'}>Plan:<Button padding={'xxs'} gap={'medium'} fontWeight={'normal'} variation='link' color={'black'} >Gibbly Starter Plan</Button></Text> */}
      {/* <Button size='small' variation={'primary'} backgroundColor={'orange.60'} onClick={() => navigate("/upgrade/pricing/")}><LightingIcon />Upgrade to Premium</Button> */}
    </RaisedCard>
  );
}
