import { Grid, View } from "@aws-amplify/ui-react";
import * as React from "react";
import SlideTimeline from "./SlideTimeline";
import { LessonContext } from "../LessonContextProvider";
import SlideView from "./SlideView";
import { SlidesContext } from "../SlidesContextProvider";
import RichTextContextProvider from "../RichTextContextProvider";
import RichTextToolbar from "./RichTextToolbar";

export interface ISlideEditorProps {}

export default function SlideEditor(props: ISlideEditorProps) {
  const {} = props;

  const { isOwner } = React.useContext(LessonContext);

  const { slideCurrent, slidePreview } = React.useContext(SlidesContext);

  // useEffect(() => {
  //   function handleKeyDown(event: KeyboardEvent) {
  //     if (event.ctrlKey || event.metaKey) {
  //       // metaKey for Mac
  //       switch (event.key) {
  //         case "z":
  //           slidesUndo();
  //           break;
  //         case "y":
  //           slidesRedo();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }

  //   // Adding the event listener
  //   window.addEventListener("keydown", handleKeyDown);

  //   // Removing event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <RichTextContextProvider>
      <Grid
        templateRows={"auto minmax(0, 1fr) auto"}
        paddingBottom={{ base: "xxxl", small: "small" }}
        templateColumns={"minmax(0,1fr)"}
        gap={"medium"}
        padding={"0"}
      >
        {isOwner ? <RichTextToolbar /> : <div></div>}
        <View padding="0 large">
          {slidePreview ? (
            <SlideView
              isStatic
              slide={slidePreview}
              idPrefix={"editor-preview-"}
            />
          ) : slideCurrent ? (
            <SlideView
              slide={slideCurrent}
              isStatic={!isOwner}
              idPrefix={"editor-"}
            />
          ) : (
            <div>Slide not found</div>
          )}
        </View>
        <SlideTimeline />
      </Grid>
    </RichTextContextProvider>
  );
}
