import {
  Card,
  Flex,
  Grid,
  Loader,
  SelectField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import React, { FormEvent, useState } from "react";
import RestAPI from "../../util/RestAPI";
import getCreatorId from "../../util/GetCreatorId";
import { getIdentityId } from "../../features/game/util";
import { MdBook, MdCottage, MdSchool, MdWork } from "react-icons/md";
import sendCustomEvent from "../../util/sendCustomEvent";
import { createRefferalWithCode } from "../../features/referrals/utils/createReferralWithCode";

import RaisedButton from "../RaisedButton";
import generateRandomNumericCode from "../../util/generateRandomNumericString";
import { updateUser } from "../../util/User/updateUser";
import { getAvailableReferralCode } from "../../util/User/getAvailableReferralCode";
import { ReferralSource } from "../../types/User";
import { invokeLambdaFunction } from "../../services/awsFacade";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { updateContactReferralCode } from "../../services/mailchimp/updateContactReferralCode";
import { refreshUserSession } from "../../services/auth/refreshUserSession";
import sendCustomEventGA from "../../util/sendCustomEventGA";

enum Role {
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
  PERSONAL = "PERSONAL",
  PROFESSIONAL = "PROFESSIONAL",
}

enum Menu {
  USER_TYPE = "USER_TYPE",
  // USERNAME = "USERNAME",
  REFERRAL = "REFERRAL",
}

export default function UserSetupModal({
  setShowModal,
}: {
  setShowModal: (arg: boolean) => void;
}) {
  const [menu, setMenu] = useState<Menu>(Menu.USER_TYPE);

  const [username] = useState(`user${generateRandomNumericCode(14)}`);
  const [referralCode, setReferralCode] = useState("");
  const [referralHasError, setReferralHasError] = useState(false);
  const [submittingReferral, setSubmittingReferral] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [referralSource, setReferralSource] = useState<ReferralSource>();
  const [otherReferralSource, setOtherReferralSource] = useState<string>();

  const [userType, setUserType] = useState<Role>();

  async function createUser(userType: Role) {
    setCreatingUser(true);
    const referralCode = await getAvailableReferralCode();
    const identityId = await getIdentityId();
    const cognitoUser = await getCurrentUser();
    const { email, sub } = await fetchUserAttributes();
    const creatorId = `${sub}::${sub}`;

    const cognitoUsername = cognitoUser.username;
    await RestAPI.post(`/users`, {
      body: {
        creatorId,
        username,
        identityId,
        userType,
        referralCode,
        tokensBase: 10000,
        tokensBonus: 0,
        tokensSpent: 0,
        customerTier: "Starter",
        trialExpiry: new Date(
          Date.now() + 1000 * 60 * 60 * 24 * 7
        ).toISOString(),
      },
    });

    await invokeLambdaFunction("AddUserToGroup", {
      username: cognitoUsername,
    });

    if (email) await updateContactReferralCode(email, referralCode);
    else {
      console.error("No email found in user attributes");
    }

    await refreshUserSession();

    // send custom event to GA
    sendCustomEvent("user_created", {
      username,
      userType,
      identityId,
      creatorId,
    });
    sendCustomEventGA(
      // DO NOT DELETE used for google ads
      {
        action: "account_setup",
        label: "account_setup",
        category: "Account",
      },
      { username, userType, identityId, creatorId }
    );

    setMenu(Menu.REFERRAL);
    setCreatingUser(false);
  }

  async function handleSubmitRefferal(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmittingReferral(true);
    setReferralHasError(false);

    const creatorId = await getCreatorId();
    const identityId = await getIdentityId();
    if (!creatorId) throw new Error("No creatorId found");
    if (!identityId) throw new Error("No identityId found");

    try {
      if (referralCode) {
        const referral = await createRefferalWithCode(
          referralCode.toUpperCase(),
          creatorId
        );
        // Reload page
        sendCustomEvent("user_referred", {
          referral,
          referralSource,
        });
      }
      if (referralSource === "other")
        await updateUser(creatorId, {
          referralSource,
          otherReferralSource,
        });
      else
        await updateUser(creatorId, {
          referralSource,
        });

      sendCustomEvent("user_finished_setup", {
        username,
        userType,
        identityId,
        creatorId,
        referralSource,
      });
      setShowModal(false);
    } catch (error) {
      // If error, show an error
      setReferralHasError(true);
      console.error("There was an error. Please try again.");
      setSubmittingReferral(false);
      return;
    }

    window.location.reload();
  }

  if (creatingUser)
    return (
      <Card backgroundColor={"background.tertiary"}>
        <Loader padding={"0 small 0 0"} /> Setting up your account
      </Card>
    );

  return (
    <>
      <Card backgroundColor={"background.tertiary"}>
        <Flex direction={"column"}>
          {/* {menu === Menu.USERNAME && (
            <Flex direction={"column"} gap={"xs"}>
              <form onSubmit={handleSubmitUsername}>
                <TextField
                  required
                  label={"Enter username"}
                  placeholder="Username"
                  value={username}
                  hasError={usernameHasError}
                  errorMessage={errorMessage}
                  onChange={(e) => setUsername(e.target.value)}
                  outerEndComponent={
                    <RaisedButton
                      type="submit"
                      variation="primary"
                      backgroundColor={"#6ed52a"}
                      isLoading={submittingUsername}
                      loadingText="Checking..."
                      data-attr="selected-username"
                    >
                      Submit
                    </RaisedButton>
                  }
                />
              </form>
            </Flex>
          )} */}
          {menu === Menu.REFERRAL && (
            <form onSubmit={handleSubmitRefferal}>
              <Flex direction={"column"} gap={"large"}>
                <SelectField
                  label={"Referral Source"}
                  labelHidden
                  placeholder="How you hear about us?"
                  value={referralSource}
                  onChange={(e) =>
                    setReferralSource(e.target.value as ReferralSource)
                  }
                  isRequired
                >
                  <option value="blog/publication">Blog/Publication</option>
                  <option value="friend">Friend/Colleague</option>
                  <option value="search-engine">Search Engine</option>
                  <option value="social-media">Social Media</option>
                  <option value="other">Other</option>
                </SelectField>
                {referralSource === "other" && (
                  <TextField
                    isRequired
                    label={"Other Referral Source"}
                    labelHidden
                    placeholder="How did you hear about us?"
                    value={otherReferralSource}
                    onChange={(e) => setOtherReferralSource(e.target.value)}
                  />
                )}
                <TextField
                  label={"Referral code"}
                  labelHidden
                  placeholder="Referral Code (optional)"
                  value={referralCode}
                  hasError={referralHasError}
                  errorMessage={"This referral code does not exist"}
                  onChange={(e) =>
                    setReferralCode(e.target.value.toUpperCase())
                  }
                />
                <RaisedButton
                  type="submit"
                  variation="primary"
                  backgroundColor={"#6ed52a"}
                  isLoading={submittingReferral}
                  loadingText="Submitting..."
                  data-attr="finished-signup"
                >
                  Finish
                </RaisedButton>
              </Flex>
            </form>
          )}

          {menu === Menu.USER_TYPE && (
            <Grid templateColumns={"1fr 1fr"} gap={"xs"}>
              <RaisedButton
                color={"white"}
                backgroundColor={"#9100ff"}
                boxShadow={
                  userType === Role.STUDENT
                    ? "0px 0px 0px 5px rgb(255,255,255, 0.5) inset"
                    : ""
                }
                onClick={async () => {
                  setUserType(Role.STUDENT);
                  await createUser(Role.STUDENT);
                }}
                data-attr="selected-user-type"
                data-ph-capture-attribute-type={"student"}
              >
                <Flex
                  direction={"column"}
                  gap={"xs"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize="small"
                >
                  <MdSchool fontSize={"xx-large"} />
                  <Text
                    color={"white"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Student
                  </Text>
                </Flex>
              </RaisedButton>
              <RaisedButton
                color={"white"}
                backgroundColor={"#de3721"}
                onClick={async () => {
                  setUserType(Role.TEACHER);
                  await createUser(Role.TEACHER);
                }}
                data-attr="selected-user-type"
                data-ph-capture-attribute-type={"teacher"}
              >
                <Flex
                  direction={"column"}
                  gap={"xs"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MdBook fontSize={"x-large"} />
                  <Text
                    color={"white"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Teacher
                  </Text>
                </Flex>
              </RaisedButton>
              <RaisedButton
                color={"white"}
                backgroundColor={"#1a90ff"}
                onClick={async () => {
                  setUserType(Role.PERSONAL);
                  await createUser(Role.PERSONAL);
                }}
                data-attr="selected-user-type"
                data-ph-capture-attribute-type={"personal"}
              >
                <Flex
                  direction={"column"}
                  gap={"xs"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MdCottage fontSize={"xx-large"} />
                  <Text
                    color={"white"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Personal
                  </Text>
                </Flex>
              </RaisedButton>
              <RaisedButton
                color={"white"}
                backgroundColor={"#ff7b00"}
                onClick={async () => {
                  setUserType(Role.PROFESSIONAL);
                  await createUser(Role.PERSONAL);
                }}
                data-attr="selected-user-type"
                data-ph-capture-attribute-type={"professional"}
              >
                <Flex
                  direction={"column"}
                  gap={"xs"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MdWork fontSize={"xx-large"} />
                  <Text
                    color={"white"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Professional
                  </Text>
                </Flex>
              </RaisedButton>
            </Grid>
          )}
        </Flex>
      </Card>
    </>
  );
}
