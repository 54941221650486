import React, { useEffect } from "react";
import {
  Button,
  Flex,
  Image,
  View,
  Text,
  Grid,
  Heading,
  Card,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { Carousel, CarouselItem, Container } from "react-bootstrap";
import logo from "../assets/logo192.png";
import { PageDescription, PageIndex, PageTitle } from "../util/SEO";
import SparkleIcon from "../components/icons/SparkleIcon";
import Footer from "../layouts/Footer";
import PlayIcon from "../components/icons/PlayIcon";
import { S3Image } from "../components/S3Image";
import {
  FaBell,
  FaPlay,
  FaSignInAlt,
} from "react-icons/fa";
import useEffectOnce from "../hooks/useEffectOnce";
import { CookieConsentForm } from "./CookieConsentForm";
import RaisedButton from "../components/RaisedButton";
import { useUserContext } from "../context/UserContextProvider";
import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import TestimonalItem from "../layouts/TestimonalItem";
import HomePageCard from "../layouts/HomePageCard";

export default function Home() {
  PageTitle("Gibbly | AI Lesson and Quiz Generators | Made For Teachers");
  PageDescription(
    "Generate ready to use and curriculum aligned gamified quizzes, assessments, lessons, and lesson plans in minutes with AI. Made for teachers. Save hours of prep time with Gibbly! "
  );
  PageIndex("");

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  useEffectOnce(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        entry.target.classList.toggle("show", entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target);
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  });

  const { authenticated } = useUserContext();

  const navigate = useNavigate();

  return (
    <>
      {CookieConsentForm()}
      <View>
        <Flex
          position="sticky"
          top={"0"}
          gap={"xs"}
          backgroundColor={"white"}
          id="highestelement"
          padding={"small"}
          boxShadow={"0px -2px rgb(0 0 0 / 10%) inset"}
          justifyContent={{ base: "center", small: "space-between" }}
          wrap="wrap"
        >
          <Flex height={"100%"} alignItems={"center"}>
            <Image
              objectFit={"cover"}
              height={"55px"}
              width={"165px"}
              src={logo}
              alt="Gibbly Logo, AI Tools for Teachers"
            />
          </Flex>

          {/* <Media queries={{ small: { maxWidth: 480 } }}>
            {(matches) =>
              matches.small ? (
                <>
                  <Container>
                    <Divider size={"small"} />
                  </Container>
                </>
              ) : (
                <></>
              )
            }
          </Media> */}

          <Flex
            padding={"xxxs"}
            alignItems={"center"}
            justifyContent={"center"}
            wrap="wrap"
          >

            <Button variation="menu"
              fontSize={{ base: "small", medium: "medium" }}
              onClick={() => navigate("/upgrade/")}
              id="raised-btn"
         
            >
              Pricing
            </Button>

            {authenticated ? (
              <>
                <RaisedButton
                  variation="menu"
                  fontSize={{ base: "small", medium: "medium" }}
                  onClick={() => navigate("/play/join/")}
                  gap={"xxs"}
                >
                  Join
                  <PlayIcon />
                </RaisedButton>

                <RaisedButton
                  variation="primary"
                  fontSize={{ base: "small", medium: "medium" }}
                  onClick={() => navigate("/dashboard/library/")}
                  gap={"xs"}
                >
                  Dashboard
                  <ArrowRightIcon />
                </RaisedButton>
              </>
            ) : (
              <>
                {
                  <>
                    <RaisedButton
                      variation="primary"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/auth/signup")}
                      gap={"small"}
                    >
                      Get Started
                    </RaisedButton>
                    <RaisedButton
                      variation="menu"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/auth/login")}
                      gap={"xs"}
                    >
                      Log In
                      <FaSignInAlt />
                    </RaisedButton>

                    <RaisedButton
                      variation="link"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/play/join/")}
                      gap={"xxs"}
                    >
                      Play
                      <PlayIcon />
                    </RaisedButton>
                  </>
                }
              </>
            )}
          </Flex>
        </Flex>

        <View
          as="section"
          className="hidden"
          padding={{ base: "small", large: "medium" }}
          paddingTop={{ base: "xs", large: "xl" }}

        >

          <Container>
            <Grid
              templateColumns={{ base: "1fr", large: "60% 40%" }}
              gap={"medium"}
              textAlign={"left"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={{ base: "0", large: "small" }}
              paddingTop={{ base: "xxxs", large: "large" }}
              paddingBottom="xl"
            >
              <Flex
                textAlign={"left"}
                direction={"column"}
                paddingTop={{ base: "xl", large: "large" }}
                paddingBottom="medium"
                gap="zero"
              >
                <Heading
                  id={"displayText"}
                  level={1}
                  fontSize={{ base: "xl", large: "xxl", xl: "xxxxl" }}
                  fontWeight={"800"}
                  padding={"medium"}
                  paddingBottom="small"
                  marginBottom={"0"}
                >
                  Generate Lessons & Quizzes with AI
                </Heading>

                <Text
                  paddingLeft={"large"}
                  paddingRight={"xl"}
                  paddingTop={"small"}
                  paddingBottom={{ base: "xl", large: "xxxs" }}
                  fontSize={{ base: "xs", medium: "medium" }}
                >
                  Save hours of prep each week with Gibbly! Generate ready to
                  use lessons, lesson plans, assessments, quizzes, and gamified
                  review assignments in minutes on any topic with AI.
                </Text>

                <Flex
                  padding={{ base: "small", medium: "large" }}
                  paddingTop={"large"}
                  justifyContent={{ base: "center", large: "left" }}
                >
                  {authenticated ? (
                    <>
                      <Button
                        id={"raised-btn"}
                        textAlign={"center"}
                        gap="small"
                        size="small"
                        fontSize={{ base: "xs", medium: "medium" }}
                        backgroundColor={"orange.60"}
                        variation="primary"
                        onClick={() => navigate("/dashboard/library")}
                      >
                        <SparkleIcon />
                        Try Today
                      </Button>
                    </>
                  ) : (
                    <>
                      {
                        <>
                          <Button
                            id={"raised-btn"}
                            gap="small"
                            textAlign={"center"}
                            size="small"
                            backgroundColor={"orange.60"}
                            fontSize={{ base: "xs", medium: "medium" }}
                            variation="primary"
                            onClick={() => navigate("/auth/signup")}
                          >
                            <SparkleIcon />
                            Sign Up Free
                          </Button>
                        </>
                      }
                    </>
                  )}

                  <a
                    href="https://www.youtube.com/watch?v=hChoaaIc6No"
                    target="_blank"
                  >
                    <Button
                      id={"raised-btn"}
                      gap="small"
                      textAlign={"center"}
                      fontSize={{ base: "xs", medium: "medium" }}
                      color="neutral.90"
                      size="small"
                      backgroundColor={"white"}
                      variation="link"
                    >
                      <FaPlay />
                      Watch Video
                    </Button>
                  </a>
                </Flex>
              </Flex>

              <Flex justifyContent={"center"}>
                <S3Image
                  bucket="gibbly-homepage-assets"
                  s3key="images/TeacherComputer.jpg"
                  alt="A teacher smiling sitting at a computer"
                  // maxHeight={{ base: "200px", medium: "300px", large: "500px" }}
                  boxShadow={"medium"}
                  borderRadius={"80px xl"}
                  width={{
                    base: "250px",
                    medium: "350px",
                    large: "500px",
                    xl: "600px",
                  }}
                ></S3Image>
              </Flex>
            </Grid>
          </Container>
        </View>

        <View
          as="section"
          paddingTop="small"
          paddingBottom="small"
          className="hidden"
          backgroundColor={"purple.60"}
          style={{ backgroundImage: "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-60))" }}
        >

          <Carousel pause="hover" indicators={false}>

            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly has been an absolute pleasure to use! It has made lesson planning so fun and easy! It has saved me so much time while creating relevant curriculum based lessons!"
                }
                name={"Melyssa - Teacher"}
                avatar="images/Teachers/Teacher1.png"
              />
            </Carousel.Item>

            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly helps take the game off the computer and into the minds of the students. Using AI to create a quiz that ties into the content and then made into a lesson sets Gibbly apart."
                }
                name={"Matthew - Teacher"}
                avatar="images/Teachers/Teacher2.png"
              />
            </Carousel.Item>


            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly is excellent for peer induced teamwork! My students love and I love having the option to have teams. Great for review or study guides."
                }
                name={" Candy - Teacher"}
                avatar="images/Teachers/Teacher3.png"
              />
            </Carousel.Item>


            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly is a teacher's dream. It cuts down on the amount of time lesson creation takes and leaves more time for other aspects of the job."
                }
                name={"Danielle - Teacher"}
                avatar="images/Teachers/Teacher4.png"
              />
            </Carousel.Item>

            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly saves so much time in creating quick, fact-based assessments that I can use as formative or summative assessments."
                }
                name={"Alie - Teacher"}
                avatar="images/Teachers/Teacher5.png"
              />
            </Carousel.Item>


            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "For all the teachers, educators, and homeschooling parents, I highly recommend Gibbly. Try it! You will not be disappointed!"
                }
                name={"Tina - Homeschooling Parent"}
                avatar="images/Teachers/Teacher6.png"
              />
            </Carousel.Item>



            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "I like that in team mode it requires students to be mindful of their turn when questions come up. I also like how quick and simple things are."
                }
                name={"George - Teacher"}
                avatar="images/Teachers/Teacher9.png"
              />
            </Carousel.Item>





            <Carousel.Item>
              <TestimonalItem
                testimonial={
                  "Gibbly is a timesaver for every teacher. It can reduce the workload off of teachers immensely. I highly recommended for schools, online teaching and training sessions."
                }
                name={"Shahinaz - Teacher"}
                avatar="images/Teachers/Teacher16.png"
              />
            </Carousel.Item>

            <CarouselItem>
              <TestimonalItem
                testimonial={
                  'I love the quiz-making feature. I teach adults 1:1 and use the quizzes as stand-alone activities. I also enjoy the space provided for adding qualifying information to the "Correct answer".'
                }
                name={"Ralph - Teacher"}
                avatar="images/Teachers/Teacher18.png"
              />
            </CarouselItem>
          </Carousel>
        </View>



        <Flex direction={"column"} rowGap={"small"}>
          <View
            as="section"
            padding={{ base: "small", large: "large" }}
            paddingTop="7vh"
            paddingBottom="9vh"
            className="hidden"
            backgroundColor="neutral.10"
          >
            <Container>
              <Flex
                direction={{ base: "column-reverse", large: "row" }}
                alignItems={{ base: "center", large: "center" }}
                columnGap={"xxl"}
              >
                <View
                  height={"100%"}
                  minHeight="100px"
                  padding={0}
                >
                  <S3Image
                    bucket="gibbly-homepage-assets"
                    s3key={"images/TeacherStudents.jpg"}
                    borderRadius={"xl 80px"}
                    alt="A female teacher talking with her two female students at a computer"
                    width={{
                      base: "250px",
                      medium: "350px",
                      large: "500px",
                      xl: "600px",
                    }}
                  />
                </View>

                <View width={{ base: "100%", large: "65%" }} >
                  <Heading
                    id={"displayText"}
                    fontWeight={"bold"}
                    level={3}
                    lineHeight={"1"}
                    fontSize={{ base: "large", large: "xl", xl: "xxl" }}
                    paddingBottom={"small"}
                    textAlign={{ base: "center", large: "left" }}
                  >
                    Save Time on Lesson Prep
                  </Heading>
                  <Text
                    paddingBottom={"large"}
                    textAlign={"left"}
                    color={"neutral.90"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Create lessons, gamified quizzes, assessments, and review
                    assignments that your students love and that save you hours
                    of prep every week.
                  </Text>

                  <Grid
                    templateColumns={{ base: "1fr", small: "1fr 1fr" }}
                    gap={"large"}
                  >
                    <HomePageCard
                      icon={
                        <Image src="/images/icons/magic-wand.png" alt="A magic wand"
                        />
                      }
                      title={"AI Lesson Generator"}
                      content={
                        "With a few button clicks, generate full lesson slides and lesson plan on any topic with AI."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image src="/images/icons/magic.png" alt="Magic sparkles" />
                      }
                      title={"AI Quiz Generator"}
                      content={
                        "Generate a gamified quiz from a YouTube video, document, topic, or pasted text in no time."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image src="/images/icons/document.png" alt="Curriculum document" />
                      }

                      title={"Curriculum Aligned"}
                      content={
                        "Paste in your curriculum expectations and standards to quickly create locally relevant learning material."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image src="/images/icons/apple.png" alt="Teacher's apple" />
                      }
                      title={"Teacher Created Resources"}
                      content={
                        " Pick from our library of ready-to-use materials made by teachers, for teachers."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image src="/images/icons/graph.png" alt="Teacher analytics dashboard" />
                      }
                      title={"Teacher Dashboard"}
                      content={
                        "Monitor student progress and see which students need additional support."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image src="/images/icons/group.png" alt="Teamwork and collaboration" />
                      }
                      title={"Collaborative Learning"}
                      content={
                        "Promote teamwork and discussion with gamified review quizzes that foster peer instruction and collaboration."
                      }
                    />
                  </Grid>
                </View>
              </Flex>
            </Container>
          </View>

          <View
            as="section"
            padding={{ base: "small", large: "large" }}
            paddingTop="10vh"
            paddingBottom="5vh"
            className="hidden"
          >
            <Container>
              <Flex width="100%" justifyContent={"center"} alignContent={"center"} alignItems={"center"} gap={"xs"} >

                <Image height={"128px"} src="/images/icons/newsletter.png" alt="An email newsletter" />
                <Card
                >
                  <Flex padding={"large"} direction={"column"}>
                    <Text
                      fontWeight={"semibold"}
                      textAlign={"center"}
                      fontSize={{ base: "small", medium: "large" }}
                      paddingBottom={"small"}
                    >
                      Stay in the loop!
                      <br />
                      Join our newsletter.
                    </Text>
                    <Flex justifyContent={"center"}>
                      <Button
                        textAlign={"center"}
                        id={"raised-btn"}
                        size="large"
                        fontSize={{ base: "medium", medium: "larger" }}
                        gap={"small"}
                        backgroundColor={"white"}
                        color={"black"}
                        variation={"primary"}
                      >
                        <a
                          href="https://mailchi.mp/e9cae978810f/newsletter-signup"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none", color: " var(--amplify-colors-neutral-90)" }}
                        >
                          <FaBell /> Subscribe
                        </a>
                      </Button>
                    </Flex>
                  </Flex>
                </Card>


              </Flex>
            </Container>
          </View>
        </Flex>

        <Footer />
      </View >
    </>
  );
}
