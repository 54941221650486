import { Flex, Heading, View, Text, Link } from "@aws-amplify/ui-react";
import * as React from "react";
import GibblyLoader from "../components/GibblyLoader";
import { useQuery } from "@tanstack/react-query";
import RestAPI from "../util/RestAPI";
import CloseIcon from "../components/icons/CloseIcon";
import { Lesson } from "../features/lesson/types/lessonTypes";
import { Slide } from "../features/lesson/types/slideTypes";
import { Presentation } from "../features/lesson/types/googlePresentationTypes";
import { SlidesRequest } from "../features/lesson/types/batchRequestTypes";
import { deltaOpsToSlideRequests } from "../features/lesson/util/deltaToGoogleSlidesRequests";
import { colorHEXtoRGB } from "../util/colorHEXtoRGB";
import { FaCheckCircle } from "react-icons/fa";
import { getS3ShortUrl } from "../services/s3/getS3ShortUrl";

type Tokens = {
  access_token: string;
  expiry_date: number;
  scope: string;
  token_type: string;
};

export interface IGenerateGoogleSlidesMenuProps {
  tokens: Tokens | any;
  onComplete?: () => void;
  onError?: (error: Error) => void;
  lesson: Lesson;
  slides: Slide[];
}

export default function GenerateGoogleSlidesMenu(
  props: IGenerateGoogleSlidesMenuProps
) {
  const { tokens, onComplete, onError, lesson, slides } = props;

  const { data: presentation, status } = useQuery(
    ["Slides Presentation", tokens],
    async () => {
      const presentation = createGooglePresentation(lesson);


      const requests: SlidesRequest[] = await createBatchUpdateRequest(slides);

      // delete first slide
      const firstSlideObjectId = presentation.slides?.[0].objectId;
      if (firstSlideObjectId !== undefined)
        requests.push({
          deleteObject: { objectId: firstSlideObjectId },
        });


      const chunkSize = 30;
      const chunkedRequests: SlidesRequest[][] = [];
      for (let i = 0; i < requests.length; i += chunkSize) {
        chunkedRequests.push(requests.slice(i, i + chunkSize));
      }



      const responses = [];
      let presentationId = null;
      for (const requests of chunkedRequests) {

        const response: any = await RestAPI.post("/google/slides", {
          body: {
            tokens,
            presentationInput: presentationId
              ? {
                  presentationId: presentationId,
                }
              : presentation,
            batchUpdateInput: {
              requests: requests,
            },
          },
        }).then((response) => response.json() as any);
        presentationId = response.presentation.presentationId;
        responses.push(response);
      }
      return responses[responses.length - 1].presentation;
    },

    {
      staleTime: Infinity,
      retry: false,
      onError: (error: Error) => {
        console.error(error);
        onError?.(error);
      },
    }
  );

  switch (status) {
    case "loading":
      return (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"small"}
          padding={"0 0 small 0"}
        >
          <View height={"80px"}>
            <GibblyLoader />
          </View>
          <Heading variation="primary">Generating Google Slides...</Heading>
        </Flex>
      );
    case "success":
      return (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"small"}
          padding={"0 0 small 0"}
        >
          <Text fontSize={"xxl"} color={"#6ed52a"}>
            <FaCheckCircle />
          </Text>
          <Heading variation="primary">Google Slides Generated!</Heading>

          <Link
            id="raised-btn"
            isExternal
            href={`https://docs.google.com/presentation/d/${presentation.presentationId}`}
            onClick={() => {
              onComplete?.();
            }}
          >
            Open Google Slides
          </Link>
        </Flex>
      );
    case "error":
      return (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"10px"}
          paddingBottom={"50px"}
        >
          <CloseIcon />
          <Heading variation="primary">Error Generating Slides</Heading>
          <Text variation="secondary">
            Please try again or contact support.
          </Text>
        </Flex>
      );
  }
}

async function createBatchUpdateRequest(slides: Slide[]) {
  const createPageRequests: SlidesRequest[] = slides.flatMap((slide) => {
    const createSlideRequest: SlidesRequest = {
      createSlide: {
        objectId: slide.id,
        slideLayoutReference: {
          predefinedLayout: "BLANK",
        },
      },
    };

    const { red, green, blue } = colorHEXtoRGB(
      slide.background.color ?? "#FFFFFF"
    );
    const updatePagePropertiesRequest: SlidesRequest = {
      updatePageProperties: {
        objectId: slide.id,
        pageProperties: {
          pageBackgroundFill: {
            solidFill: {
              color: {
                rgbColor: {
                  red: red,
                  green: green,
                  blue: blue,
                },
              },
            },
          },
        },
        fields: "pageBackgroundFill",
      },
    };

    return [createSlideRequest, updatePagePropertiesRequest];
  });

  const insertPageElementsRequestPromises = slides.flatMap((slide) =>
    slide.elements.flatMap(async (element) => {
      let request: SlidesRequest | SlidesRequest[] = [];

      if (element.elementType === "text") {
        // remove \n if at end of text
        const text = element.props.ops
          .map((op) => op.insert)
          .join("")
          //remove trailng \n
          .replace(/\n$/, "");
        if (text === "") return [];

        const createRequests: SlidesRequest[] = [
          {
            createShape: {
              objectId: element.id,
              shapeType: "TEXT_BOX",
              elementProperties: {
                pageObjectId: slide.id,
                size: {
                  height: {
                    magnitude: (element.positionProps.h as number) * 72,
                    unit: "PT",
                  },
                  width: {
                    magnitude: (element.positionProps.w as number) * 72,
                    unit: "PT",
                  },
                },
                transform: {
                  scaleX: 1,
                  scaleY: 1,
                  translateX: (element.positionProps.x as number) * 72,
                  translateY: (element.positionProps.y as number) * 72,
                  unit: "PT",
                },
              },
            },
          },
          {
            updateShapeProperties: {
              objectId: element.id,
              fields: "contentAlignment",
              shapeProperties: {
                contentAlignment: element.props.valign?.toUpperCase() as
                  | "TOP"
                  | "MIDDLE"
                  | "BOTTOM",
              },
            },
          },
          {
            insertText: {
              objectId: element.id,
              text,
            },
          },
        ];

        let styleRequests: SlidesRequest[] = deltaOpsToSlideRequests(element);

        request = [...createRequests, ...styleRequests];
      } else if (element.elementType === "image") {
        const { key, identityId } = element.s3Item;
        // const { key, identityId, level } = element.s3Item;
        if (key === undefined) return [];
        await getS3ShortUrl(`protected/${identityId}/${key}`)
          .then((url) => {
            // await getUrl(key, { identityId, level }).then((url) => {
            request = {
              createImage: {
                objectId: element.id,
                url,
                elementProperties: {
                  pageObjectId: slide.id,
                  size: {
                    height: {
                      magnitude: (element.props.sizing?.h as number) * 72,
                      unit: "PT",
                    },
                    width: {
                      magnitude: (element.props.sizing?.w as number) * 72,
                      unit: "PT",
                    },
                  },
                  transform: {
                    scaleX: 1,
                    scaleY: 1,
                    translateX: (element.props.sizing?.x as number) * 72,
                    translateY: (element.props.sizing?.y as number) * 72,
                    unit: "PT",
                  },
                },
              },
            };
            return request;
          })
          .catch((e) => {
            console.error(e);
            return [];
          });
      }
      // );
      // }

      return request;
    })
  );

  // const instertPageElementsRequests = await Promise.all(
  //   insertPageElementsRequestPromises
  // );

  const insertPageElementsRequests2D = await Promise.all(
    insertPageElementsRequestPromises.flatMap(async (request) => await request)
  );

  const insertPageElementsRequests = insertPageElementsRequests2D.flat();

  return [...createPageRequests, ...insertPageElementsRequests];
}

function createGooglePresentation(lesson: Lesson) {
  // if lesson.title empty, then lesson.topic
  // if lesson.topic empty, "Gibbly Lesson"
  const title =
    lesson.title === ""
      ? lesson.topic === ""
        ? "Gibbly Lesson"
        : lesson.topic
      : lesson.title;

  const presentation: Presentation = {
    title,
  };

  return presentation;
}
