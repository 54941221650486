import React from "react";
import PresentView from "../PresentView";
import SlidesContextProvider from "../SlidesContextProvider";
import LessonContextProvider from "../LessonContextProvider";
import { useParams } from "react-router-dom";
import useSlidesQuery from "../../../../hooks/lessons/useSlidesQuery";
import useLessonQuery from "../../../../hooks/lessons/useLessonQuery";
import useLessonLinkQuery from "../../../../hooks/lessons/useLessonLinkQuery";
import { ScreenReaderContextProvider } from "../../../accessibility/context";
import TranslationContextProvider from "../../../../context/TranslationContextProvider";

export default function SharedPresentation() {
  const { lessonId, linkId } = useParams();

  useLessonLinkQuery(lessonId, linkId, { suspense: true });

  const { lesson } = useLessonQuery(lessonId, { suspense: true });

  const { slides } = useSlidesQuery(lessonId, { suspense: true });

  if (!lesson) {
    return null;
  }

  return (
    <TranslationContextProvider lang={lesson.lang}>
      <ScreenReaderContextProvider>
        <LessonContextProvider lessonServer={lesson} isOwner={false}>
          <SlidesContextProvider slidesServer={slides}>
            <PresentView autoPresent={true} />
          </SlidesContextProvider>
        </LessonContextProvider>
      </ScreenReaderContextProvider>
    </TranslationContextProvider>
  );
}
