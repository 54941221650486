import * as React from "react";
import { Lesson, Level, Privacy } from "../types/lessonTypes";
import { createContext } from "react";
import { S3Item } from "../../../types/S3File";
import { updateLesson } from "../services/updateLesson";
import { useOptimisticDebounce } from "../../../hooks/useOptimisticStateDebounce";
import TranslationContextProvider from "../../../context/TranslationContextProvider";

interface ILessonContext {
  lesson: Lesson;
  isOwner: boolean | undefined;
  outputLang: "en" | "fr";
  setOutputLang: (value: "en" | "fr") => void;
  setTopic: (value: string) => void;
  setOverview: (value: string) => void;
  setTitle: (value: string) => void;
  setDescription: (value: string) => void;
  setObjectives: (value: string) => void;
  setScope: (value: string) => void;
  setVocabulary: (value: string) => void;
  setGradeLevel: (value: Level) => void;
  setReadingLevel: (value: Level) => void;
  setLanguage: (value: string) => void;
  setPrivacy: (value: Privacy) => void;
  setImage: (image: S3Item) => void;
}

export const LessonContext = createContext<ILessonContext>(
  {} as ILessonContext
);

export interface ILessonContextProviderProps {
  children: React.ReactNode;
  lessonServer: Lesson;
  isOwner: boolean;
}

export default function LessonContextProvider(
  props: ILessonContextProviderProps
) {
  const { children, lessonServer, isOwner } = props;

  const [lesson, setLesson] = React.useState(lessonServer);

  const [outputLang, setOutputLang] = React.useState<"en" | "fr">(
    lesson.lang === "en" || lesson.lang === "fr" ? lesson.lang : "en"
  );

  React.useEffect(() => {
    setOptimisticLesson((prev) => {
      if (prev.lang === outputLang) return prev;
      return { ...prev, lang: outputLang };
    });
  }, [outputLang]);

  const [optimisticLesson, setOptimisticLesson] = useOptimisticDebounce<Lesson>(
    lesson,
    500,
    async (lesson) => {
      const { userId, id, updatedAt, createdAt, ...updateItem } = lesson;
      const updatedLesson = await updateLesson(lesson.id, updateItem);
      if (updatedLesson) setLesson(updatedLesson);
      else setOptimisticLesson(lesson, true);
    }
  );

  React.useEffect(() => {
    setOutputLang((prev) => {
      if (prev === optimisticLesson.lang) return prev;
      if (optimisticLesson.lang === "en" || optimisticLesson.lang === "fr")
        return optimisticLesson.lang;
      return prev;
    });
  }, [optimisticLesson.lang]);

  React.useEffect(() => {}, [lesson.gradeLevel, lesson.readingLevel]);

  return (
    <TranslationContextProvider lang={lesson.lang}>
      <LessonContext.Provider
        value={{
          lesson: optimisticLesson,
          isOwner,
          outputLang,
          setOutputLang,
          setTopic: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, topic: value })),
          setOverview: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, overview: value })),
          setTitle: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, title: value })),
          setDescription: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, description: value })),
          setObjectives: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, objectives: value })),
          setScope: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, coverage: value })),
          setVocabulary: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, vocabulary: value })),
          setGradeLevel: (value: Level) =>
            setOptimisticLesson((prev) => ({ ...prev, gradeLevel: value })),
          setReadingLevel: (value: Level) =>
            setOptimisticLesson((prev) => ({ ...prev, readingLevel: value })),
          setLanguage: (value: string) =>
            setOptimisticLesson((prev) => ({ ...prev, lang: value })),
          setPrivacy: (value: Privacy) =>
            setOptimisticLesson((prev) => ({ ...prev, privacy: value })),
          setImage: (image: S3Item) =>
            setOptimisticLesson((prev) => ({ ...prev, image: image })),
        }}
      >
        {children}
      </LessonContext.Provider>
    </TranslationContextProvider>
  );
}
