import { Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";
import GeneratedSlidePreview from "./GeneratedSlidePreview";
import { Slide } from "../../../types/slideTypes";
import { SlidesContext } from "../../SlidesContextProvider";

export interface IGeneratedSlideCollectionProps {
  slides: Slide[];
  setSlides: React.Dispatch<React.SetStateAction<Slide[]>>;
  loading: boolean;
}

export default function GeneratedSlideCollection(
  props: IGeneratedSlideCollectionProps
) {
  const { slides, setSlides, loading } = props;

  const { addSlide, slideIndex, setSlidePreview } =
    React.useContext(SlidesContext);

  return (
    <Flex direction="column" width={"100%"} gap={"xxs"} padding={"medium xs"}>
      {slides.map((slide) => (
        <View
        key={slide.id}
          height={"180px"}
          style={{ aspectRatio: "16/9" }}
        >
          <GeneratedSlidePreview
            loading={loading}
            slide={slide}
            updateSlide={(newSlide) => {
              setSlides((prev) =>
                prev.map((prevSlide) => (prevSlide.id === newSlide.id ? newSlide : prevSlide))
              );
            }}
            addSlide={(slide) => {
              let orderIndex = slideIndex + 1;

              addSlide(slide, orderIndex);
              setSlidePreview(undefined);
              // remove slide from slides
              setSlides((prev) => prev.filter((p) => p.id !== slide.id));
            }}
          />
        </View>
      ))}
    </Flex>
  );
}
