import * as React from "react";
import useChatStream from "../../../hooks/useChatStream";
import { Alert, Flex } from "@aws-amplify/ui-react";
import GeneratedQuestionCard from "./GeneratedQuestionCard";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { toast } from "react-toastify";
import { QuestionType } from "../../../API";
import { useCallback, useEffect } from "react";
import { fixGeneratedQuestion } from "../util/fixGeneratedQuestion";
import { GeneratedQuestion } from "../types/GeneratedQuestion";

export interface IQuestionGeneratorProps {
  input: any;
  // topic: string;
  // gradeLevel: string;
  // numberOfQuestions: string;
  type: "yt-mixed" | "mixed" | "doc-mixed" | "paste-mixed";
  setQuestionsLoading: (loading: boolean) => void;
  questions: FixedQuestion[];
  setQuestions: (questions: FixedQuestion[]) => void;
  keepArray: boolean[];
  pushKeep: (keep: boolean) => void;
  updateKeep: (index: number, keep: boolean) => void;
  clearKeepArray: () => void;
  lang?: string;
}

export type FixedQuestion = {
  text: string;
  answers: string[];
  correctIndices: number[];
  type: QuestionType;
  image?: {
    key: string;
    identityId: string;
    alt: string;
  };
};

export interface QuestionGeneratorHandle {
  generateQuestions: () => Promise<void>;
}

const QuestionGenerator = React.forwardRef<
  QuestionGeneratorHandle,
  IQuestionGeneratorProps
>((props, ref) => {
  const {
    type,
    setQuestionsLoading,
    questions,
    setQuestions,
    keepArray,
    pushKeep,
    updateKeep,
    clearKeepArray,
    lang,
    input,
  } = props;

  // const initialMessage = React.useMemo(
  //   () => JSON.stringify({ type: "mixed", input }),
  //   [input]
  // );

  const { connect, loading, output } = useChatStream({
    streamType: "list",
    type: type,
    inputObject: input,
    language: lang,
    onError: (err) => {
      toast.error(err.message);
    },
  });

  // useEffect(() => {

  // }, [type]);
  // useEffect(() => {

  // }, [input]);

  React.useEffect(() => {
    setQuestionsLoading(loading);
  }, [loading]);

  const onOutputLengtChange = React.useCallback(() => {
    if (!output) setQuestions([]);
    if (output.length === 0) return;
    const parsedOutput = output.map((q) => {
      try {
        return { ...JSON.parse(q) } as GeneratedQuestion;
      } catch {
        return null;
      }
    });
    // setQuestions(parsedOutput.filter((q) => q !== null) as GeneratedQuestion[]);
    const correctedOutput = fixQuestionArray(parsedOutput);
    setQuestions([...questions, correctedOutput[correctedOutput.length - 1]]);
  }, [output]);

  useUpdateEffect(() => {
    onOutputLengtChange();
  }, [output.length]);

  const fixQuestionArray = (
    q: (GeneratedQuestion | null)[]
  ): FixedQuestion[] => {
    return q
      .map((q) => fixGeneratedQuestion(q))
      .filter((q) => q !== null) as FixedQuestion[];
  };

  const generateQuestionsCallback = React.useCallback(async () => {
    await connect();
  }, [connect]);

  React.useImperativeHandle(
    ref,
    () => ({
      generateQuestions: async () => await generateQuestionsCallback(),
    }),
    [generateQuestionsCallback]
  );

  const adjustKeepArray = useCallback(() => {
    // make sure the keep array is the same length as the questions array
    if (questions.length === 0) clearKeepArray();
    if (keepArray.length === questions.length) return;
    if (questions.length > keepArray.length) {
      const diff = questions.length - keepArray.length;
      for (let i = 0; i < diff; i++) {
        pushKeep(true);
      }
    }
  }, [questions.length, keepArray.length]);

  useEffect(() => {
    adjustKeepArray();
  }, [questions.length]);

  return (
    <Flex direction={"column"} gap={"xs"}>
      {questions?.map((q, i) => (
        <GeneratedQuestionCard
          key={i}
          question={q}
          keep={keepArray[i]}
          toggleKeep={() => updateKeep(i, !keepArray[i])}
        />
      ))}

      <Alert
        fontSize={"small"}
        variation="info"
        isDismissible
        heading="Best Practices"
      >
        AI might occasionally produce biased, incorrect, or silly content.
        Always review before sharing with students.
      </Alert>
    </Flex>
  );
});

export default QuestionGenerator;
