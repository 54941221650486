import { Button, Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";

import { LessonContext } from "../../LessonContextProvider";
import useEffectOnce from "../../../../../hooks/useEffectOnce";

import RaisedButton from "../../../../../components/RaisedButton";
import SparkleIcon from "../../../../../components/icons/SparkleIcon";

import GeneratedSlideCollection from "./GeneratedSlideCollection";
import useContentSlideGenerator from "./hooks/useContentSlideGenerator";
import { Slide } from "../../../types/slideTypes";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import { SlidesContext } from "../../SlidesContextProvider";

export interface IMainContentGeneratorProps {
  useLessonPlan: boolean;
  useExistingTopic: boolean;
  topicLocal: string;
}

export default function MainContentGenerator(
  props: IMainContentGeneratorProps
) {
  const { useLessonPlan, useExistingTopic, topicLocal } = props;

  const { lesson } = React.useContext(LessonContext);

  const [slides, setSlides] = React.useState<Slide[]>([]);

  const {
    connect: connectContent,
    loading: loadingContent,
    slides: slidesContent,
  } = useContentSlideGenerator({
    useLessonPlan,
    useExistingTopic,
    lesson,
    topicLocal,
  });

  const connect = React.useCallback(() => {
    connectContent();
  }, []);

  const loading = React.useMemo(() => loadingContent, [loadingContent]);

  useEffectOnce(() => {
    connect();
  });

  useUpdateEffect(() => {
    setSlides([...slidesContent]);
  }, [slidesContent]);

  const { addSlide, slideIndex, setSlidePreview } =
    React.useContext(SlidesContext);
  const addAllSlides = () => {
    slides.forEach((slide, i) => {
      addSlide(slide, slideIndex + 1 + i);
    });
    setSlides([]);
    setSlidePreview(undefined);
  };

  return (
    <View>
      <Flex justifyContent={"center"}>
        <RaisedButton
          fontSize={"small"}
          variation={"primary"}
          size="small"
          textAlign={"center"}
          color={"white"}
          backgroundColor={"orange.60"}
          gap="small"
          isLoading={loading}
          loadingText={"Generating..."}
          onClick={connect}
          data-attr="create-lesson-slides"
          data-ph-capture-attribute-type={"regenerate-content-slides"}
          data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
          data-ph-capture-attribute-grade-level={lesson.gradeLevel}
          data-ph-capture-attribute-reading-level={lesson.readingLevel}
          data-ph-capture-attribute-language={lesson.lang}
        >
          <SparkleIcon />
          Regenerate
        </RaisedButton>

        <Button
          fontSize={"small"}
          variation={"link"}
          size="small"
          color={"neutral.80"}
          textAlign={"center"}
          textDecoration={"underline"}
          hidden={loading || slides.length === 0}
          onClick={addAllSlides}
          data-attr="add-lesson-slides"
          data-ph-capture-attribute-type={"add-all-content-slides"}
          data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
          data-ph-capture-attribute-grade-level={lesson.gradeLevel}
          data-ph-capture-attribute-reading-level={lesson.readingLevel}
          data-ph-capture-attribute-language={lesson.lang}
        >
          Add All {slides.length} Slides
        </Button>
      </Flex>

      <GeneratedSlideCollection
        slides={slides}
        setSlides={setSlides}
        loading={loading}
      />
    </View>
  );
}
