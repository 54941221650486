import React from "react";
import { Flex, Divider, Text, ResponsiveStyle, Image } from "@aws-amplify/ui-react";

import { Property } from "csstype";
import SidebarNavLink from "./SidebarNavLink";
import SidebarCreateButton from "./SidebarCreateButton";

export default function SideNavbar({
  area,
  hidden,
}: {
  area?: ResponsiveStyle<Property.GridArea>;
  hidden?: boolean;
}) {
  return (
    <>
      <Flex
        direction={"column"}
        boxShadow={"-2px 0px rgb(0 0 0 / 10%) inset"}
        padding={"xxs"}
        area={area}
        hidden={hidden}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex direction={"column"} gap={"0.1em"}>
          <SidebarCreateButton navTitle={"Create"} posthogType={"navbar"} />

          <Divider
            padding={"0.1rem"}
            style={{ borderBottom: "2px solid rgba(0,0,0, 0.2)" }}
          />
          <SidebarNavLink
            navIcon={
              <Image src="/images/icons/compass.png" alt="A compass" padding={"2px"} objectFit={"contain"} height={"large"} />
            }
            navTitle={"Discover"}
            to={"/dashboard/discover/"}
            color={"neutral.80"}
          />

          <SidebarNavLink
            navIcon={
              <Image src="/images/icons/books.png" alt="A stack of books" padding={"2px"} objectFit={"contain"} height={"large"} />
            }
            navTitle={"My Library"}
            to={"/dashboard/library/"}
            color={"neutral.80"}
          />

          <SidebarNavLink
            navIcon={
              <Image src="/images/icons/mortarboard.png" alt="A graduation cap" padding={"2px"} objectFit={"contain"} height={"large"} />
            }
            navTitle={"Classes"}
            to={"/classes"}
            color={"neutral.80"}
          />

          <Divider
            padding={"0.1rem"}
            style={{ borderBottom: "2px solid rgba(0,0,0, 0.2)" }}
          />

          <SidebarNavLink
            navIcon={
              <Image src="/images/icons/gear.png" alt="A cog" padding={"2px"} objectFit={"contain"} height={"large"} />
            }
            navTitle={"Settings"}
            to="/settings"
            color={"neutral.80"}
          />
          <SidebarNavLink
            navIcon={
              <Image src="/images/icons/crown.png" alt="A gold crown" padding={"2px"} objectFit={"contain"} height={"large"} />
            }
            navTitle={"Upgrade"}
            to="/dashboard/upgrade"
            color={"neutral.80"}
          />
        </Flex>

        <Flex>
          <Text textAlign={"center"} fontSize={"xxxs"} color="neutral.80">
            © 2024 Gibbly Inc
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
