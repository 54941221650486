import * as React from "react";
import pptxgen from "pptxgenjs";
import { SlidesContext } from "./SlidesContextProvider";
import { deltaOpsToTextPropsV2 } from "../util/deltaToTextProps";
import { LessonContext } from "./LessonContextProvider";
import RestAPI from "../../../util/RestAPI";
import useUrl from "../../../hooks/useUrl";
import GenerateGoogleSlidesMenu from "../../../layouts/GenerateGoogleSldiesMenu";
import useModal from "../../../hooks/useModal";
import sendCustomEvent from "../../../util/sendCustomEvent";
import { toast } from "react-toastify";
import PresentView from "./PresentView";
import { getS3Url } from "../../../services/s3/getS3Url";

interface IPresentationContext {
  startPresentation: () => void;
  exportToPowerPoint: () => Promise<void>;
  exportToGoogleSlides: () => Promise<void>;
  isPresenting: boolean;
  googleExporting: boolean;
  pptxExporting: boolean;
}

export const PresentationContext = React.createContext<IPresentationContext>(
  {} as IPresentationContext
);

export interface IPresentationContextProviderProps {
  children?: React.ReactNode;
}

export default function PresentationContextProvider(
  props: IPresentationContextProviderProps
) {
  const { children } = props;

  const { slides, setSlideIndex, nextSlide, prevSlide } =
    React.useContext(SlidesContext);

  const { lesson } = React.useContext(LessonContext);

  const [isPresenting, setIsPresenting] = React.useState<boolean>(false);

  const [googleExporting, setGoogleExporting] = React.useState<boolean>(false);
  const [pptxExporting, setPptxExporting] = React.useState<boolean>(false);

  const startPresentation = React.useCallback(() => {
    const presentationElement = document.getElementById("presentation-view");

    setSlideIndex(0);

    if (!document.fullscreenElement) {
      if (presentationElement?.requestFullscreen) {
        presentationElement.requestFullscreen();
        setIsPresenting(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsPresenting(false);
      }
    }
  }, []);

  React.useEffect(() => {
    function handleFullscreenChange() {
      if (document.fullscreenElement) {
        setIsPresenting(true);
      } else {
        setIsPresenting(false);
      }
    }
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Function that will be called when left arrow is pressed
  const handleLeftArrowPress = React.useCallback(() => {
    prevSlide();
  }, [prevSlide]);

  // Function that will be called when right arrow is pressed
  const handleRightArrowPress = React.useCallback(() => {
    nextSlide();
  }, [nextSlide]);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case "ArrowLeft":
          handleLeftArrowPress();
          break;
        case "ArrowRight":
          handleRightArrowPress();
          break;
        default:
          break;
      }
    },
    [handleLeftArrowPress, handleRightArrowPress]
  );
  // Register keydown event listener
  React.useEffect(() => {
    if (isPresenting) window.addEventListener("keydown", handleKeyDown);
    else window.removeEventListener("keydown", handleKeyDown);
    // Cleanup
    return () => {
      if (isPresenting) window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPresenting, handleKeyDown]);

  // Function to create a presentation
  const createPresentation = React.useCallback(async () => {
    setPptxExporting(true);
    sendCustomEvent("export-pptx-start");
    let pres = new pptxgen();
    pres.title = lesson.title ?? "untitled";
    pres.subject = lesson.topic;
    try {
      await Promise.all(
        slides.map(async (slide) => {
          let pptxSlide = pres.addSlide();
          pptxSlide.background = slide.background;
          await Promise.all(
            slide.elements.map(async (slideElement) => {
              if (slideElement.elementType === "text") {
                const { props, positionProps } = slideElement;
                const { ops, ...options } = props;
                // const textProps = deltaOpsToTextProps(ops);
                const textPropsV2 = deltaOpsToTextPropsV2(slideElement);

                pptxSlide.addText([...textPropsV2], {
                  ...options,
                  ...positionProps,
                });
              } else if (slideElement.elementType === "image") {
                // use the image bucket and identityId to create a new URL
                const { key, identityId, level } = slideElement.s3Item;
                if (!key) return;
                const { url } = await getS3Url({
                  version: 2,
                  path: `${level}/${identityId}/` + key,
                });
                const props: pptxgen.ImageProps = { ...slideElement.props };
                props.path = url.toString();

                pptxSlide.addImage(props);
              }
            })
          );
        })
      );

      await pres.writeFile({
        fileName: `${pres.title !== "" ? pres.title : "untitled"}.pptx`,
      });
      sendCustomEvent("export-pptx-success");
      setPptxExporting(false);
    } catch (e) {
      console.error(e);
      sendCustomEvent("export-pptx-fail");
      toast.error("Something went wrong. Please try again.");
      setPptxExporting(false);
    }
  }, [lesson, slides]);

  const exportToGoogleSlides = React.useCallback(async () => {
    setGoogleExporting(true);
    sendCustomEvent("export-slides-start");
    try {
      const response = await RestAPI.get("/google/slides/consent").then((res) =>
        res.json()
      );

      const { url } = response as any;
      const width = 600;
      const height = 600;
      // eslint-disable-next-line no-restricted-globals
      const left = screen.width / 2 - width / 2;
      // eslint-disable-next-line no-restricted-globals
      const top = screen.height / 2 - height / 2;

      const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;

      window.open(url, "Google Slides Consent", windowFeatures);
      setGoogleExporting(false);
    } catch (e) {
      sendCustomEvent("export-slides-fail");
      toast.error("Something went wrong. Please try again.");
      setGoogleExporting(false);
    }
  }, []);

  const [tokens, setTokens] = React.useState<any>(null);

  const [GoogleSlidesModal, setShowGoogleSlidesModal] = useModal(
    {
      title: "",
      ReactComponent: () => (
        <GenerateGoogleSlidesMenu
          tokens={tokens}
          lesson={lesson}
          slides={slides}
          onComplete={() => {
            setShowGoogleSlidesModal(false);
          }}
          onError={() => {
            toast.error("Something went wrong. Please try again.");
            setShowGoogleSlidesModal(false);
          }}
        />
      ),
    },
    [tokens, lesson, slides]
  );

  const { root } = useUrl();

  // Recieve tokens from consent page
  React.useEffect(() => {
    const messageListener = (event: MessageEvent<any>) => {
      if (event.origin !== root) {
        return; // Ignore messages from unknown sources for security
      }

      if (
        event.data.type === "CONSENT_ERROR" &&
        event.data.error !== undefined
      ) {
        console.error(event.data.error);
        toast.error("Something went wrong. Please try again.");
        sendCustomEvent("export-slides-fail");
        setGoogleExporting(false);
      } else if (
        event.data.type === "SLIDES_TOKENS" &&
        event.data.tokens !== undefined
      ) {
        setTokens(event.data.tokens);
        setShowGoogleSlidesModal(true);
        sendCustomEvent("export-slides-success");
        setGoogleExporting(false);
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <PresentationContext.Provider
      value={{
        startPresentation,
        isPresenting,
        exportToPowerPoint: createPresentation,
        exportToGoogleSlides,
        googleExporting,
        pptxExporting,
      }}
    >
      <>
        <GoogleSlidesModal />
        {children}
        <PresentView />
      </>
    </PresentationContext.Provider>
  );
}
