import React, { useCallback, useEffect, useMemo, useState } from "react";
import RaisedCard from "../../../components/RaisedCard";
import { Button, Flex, Image, Link, Text } from "@aws-amplify/ui-react";
import useDebounce from "../../../hooks/useDebounce";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FaTimes } from "react-icons/fa";
import { useUserContext } from "../../../context";
import goldCrown from "/images/icons/crown.png";
import RestAPI from "../../../util/RestAPI";
import { FreeTrial } from "../types";
import useToggle from "../../../hooks/useToggle";
import { startFreeTrial } from "../services";

type FreeTrialToastProps = {};

export function FreeTrialToast({}: FreeTrialToastProps) {
  const [timeLeft, setTimeLeft] = useState<string | undefined>(undefined);
  const [toggle, setToggle] = useToggle(true);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const { user, isSubscribed } = useUserContext();

  useEffect(() => {
    updateTimeLeft();
  }, []);

  const { data: freeTrials } = useQuery({
    queryKey: ["free-trial", "list", user?.identityId],
    queryFn: async () => {
      return await RestAPI.get("/free-trials").then(async (res) => {
        return (await res.json()) as any as FreeTrial[];
      });
    },
    onSuccess: (data) => {
      if (data.length === 0 && user?.customerTier !== "Premium")
        startTrial_mutateAsync();
    },
    enabled: !!user,
    refetchInterval: 1000 * 15,
  });

  const currentTrial = useMemo(() => {
    return freeTrials?.find((trial) => {
      return trial.trialStatus === "active";
    });
  }, [freeTrials]);

  const recentlyExpiredTrial = useMemo(() => {
    return freeTrials?.find((trial) => {
      return (
        trial.trialStatus === "expired" &&
        Date.now() - Date.parse(trial.expiry) < 1000 * 60 * 60 * 24 * 3
      );
    });
  }, [freeTrials]);

  const { mutateAsync: startTrial_mutateAsync } = useMutation({
    mutationKey: ["start-trial"],
    mutationFn: async () => {
      if (!user) return;
      await startFreeTrial({
        userId: user.identityId,
        username: user.username,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["free-trial"]);
      setTimeLeft(undefined);
    },
  });

  // const { mutateAsync: endTrial_mutateAsync } = useMutation({
  //   mutationKey: ["end-trial"],
  //   mutationFn: async () => {
  //     if (!user) return;
  //     await updateUser(user.creatorId, {
  //       customerTier: "Starter",
  //     });
  //     await RestAPI.put("/free-trials", {
  //       body: {
  //         ...currentTrial,
  //         status: "expired",
  //       },
  //     });
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(["user"]);
  //     queryClient.invalidateQueries(["free-trial"]);
  //     setTimeLeft(undefined);
  //   },
  // });

  function calculateTimeLeft(expiry: string | null | undefined): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } {
    if (expiry === undefined || expiry === null)
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    const now = Date.now();
    const timeLeft = Date.parse(expiry) - now;
    const daysLeft = Math.max(0, Math.round(timeLeft / (1000 * 60 * 60 * 24)));
    const hoursLeft = Math.max(
      0,
      Math.floor((timeLeft / (1000 * 60 * 60)) % 24)
    );
    const minutesLeft = Math.max(0, Math.floor((timeLeft / (1000 * 60)) % 60));
    const secondsLeft = Math.max(0, Math.floor(timeLeft / 1000));

    return {
      days: daysLeft,
      hours: hoursLeft,
      minutes: minutesLeft,
      seconds: secondsLeft,
    };
  }

  const updateTimeLeft = useCallback(() => {
    setTimeLeft(() => {
      const { days, hours } = calculateTimeLeft(currentTrial?.expiry);

      if (days > 0) {
        return `${days} days`;
      } else if (hours > 0) {
        return `${hours} hours`;
      } else {
        return `< 1 hour`;
      }
    });
  }, [user, currentTrial]);

  useDebounce(
    () => {
      updateTimeLeft();
      setToggle();
    },
    1000,
    [user, timeLeft, toggle]
  );

  if (isSubscribed) return null;

  if (recentlyExpiredTrial)
    return isOpen ? (
      <RaisedCard
        position={"fixed"}
        bottom={"small"}
        right={"large"}
        style={{ zIndex: 1000 }}
      >
        <Flex alignItems={"center"}>
          <Image alt="premium-crown" src={goldCrown} height={"20px"}></Image>

          <Text fontSize={"small"}>
            Your Trial has Ended.{" "}
            <Link
              isExternal
              href="/dashboard/upgrade"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <b>
                <u>Upgrade Today</u>
              </b>
            </Link>
          </Text>
          <Button
            variation="link"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FaTimes />
          </Button>
        </Flex>
      </RaisedCard>
    ) : null;

  if (!currentTrial) return null;

  return isOpen ? (
    <RaisedCard
      position={"fixed"}
      bottom={"small"}
      right={"large"}
      style={{ zIndex: 1000 }}
    >
      <Flex alignItems={"center"}>
        <Image alt="premium-crown" src={goldCrown} height={"20px"}></Image>

        <Text fontSize={"small"}>
          Your Free Trial Ends in <b>{timeLeft}</b>
        </Text>
        <Button
          variation="link"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <FaTimes />
        </Button>
      </Flex>
    </RaisedCard>
  ) : null;
}
