import * as React from "react";
import { SlideElement } from "../../types/slideTypes";
import ImageElementView from "./ImageElementView";
import { SlideElementsContext } from "../SlideElementsContextProvider";
import { computeElementStyles } from "../../util/computeElementStyles";
import { View } from "@aws-amplify/ui-react";
import TextElement from "./TextElementQuill";

export interface ISlideElementViewProps {
  slideElement: SlideElement;
}

export default function SlideElementView(props: ISlideElementViewProps) {
  const { slideElement } = props;

  const { selectedElements, isStatic } = React.useContext(SlideElementsContext);

  // const handleElementClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   setSelectedElement(slideElement);
  // };

  const selected = React.useMemo(
    () => selectedElements.includes(slideElement.id),
    [selectedElements, slideElement]
  );

  // const elementRef = useClickOutsideDetection(
  //   (e) => {
  //     if (!selected) return;
  //     const toolbar = document.getElementById(
  //       "slide-toolbar-" + slideElement.id
  //     );

  //     if (toolbar && toolbar.contains(e.target as Node)) return;


  //     setSelectedElement(null);
  //   },
  //   [selected, slideElement.id]
  // );

  const elementStyles = React.useMemo(() => {
    if (slideElement.elementType === "image") {
      let { x, y, w, h } = slideElement.props.sizing ?? {
        x: 1,
        y: 1,
        w: 1,
        h: 1,
      };
      return {
        ...computeElementStyles({ x, y, w, h }),
        padding: "0",
      };
    } else if (slideElement.elementType === "text") {
      let { x, y, w, h } = slideElement.positionProps ?? {
        x: 1,
        y: 1,
        w: 1,
        h: 1,
      };

      return {
        ...computeElementStyles({ x, y, w, h }),
        padding: "0",
      };
    }
    return {};
  }, [slideElement]);

  return (
    <View
      // ref={elementRef}
      className={
        isStatic ? "" : "slide-element" + (selected ? " selected" : "")
      }
      // onClick={isStatic ? undefined : handleElementClick}
      style={elementStyles}
      // style={
      //   selected
      //     ? {
      //         ...elementStyles,
      //       }
      //     : elementStyles
      // }
    >
      {slideElement.elementType === "text" && (
        <TextElement key={slideElement.id} slideElement={slideElement} />
      )}
      {slideElement.elementType === "image" && (
        <ImageElementView key={slideElement.id} slideElement={slideElement} />
      )}
    </View>
  );
}
