import * as React from "react";

import { SlideElementsContext } from "../SlideElementsContextProvider";
import { SlideElement, TextProps } from "../../types/slideTypes";
import Quill, { RangeStatic } from "quill";
import { convertTextPropsToDelta } from "../../util/textPropsToDelta";
import { SlidesContext } from "../SlidesContextProvider";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { toast } from "react-toastify";
import Delta from "quill-delta";
import { useRichTextContext } from "../RichTextContextProvider";

import "../../../../styles/quill.css";

export interface ITextElementProps {
  slideElement: SlideElement;
}

export default function TextElement(props: ITextElementProps) {
  const { isStatic, onChangeElement, idPrefix } =
    React.useContext(SlideElementsContext);

  const { setCurrentEditor, setCurrentFormats } = useRichTextContext();

  const { slideIndex } = React.useContext(SlidesContext);
  const { selectElement, removeSelectedElement } =
    React.useContext(SlideElementsContext);

  const { slideElement: thisElement } = props;

  let Inline = Quill.import("blots/inline");
  class MarkBlot extends Inline {}
  MarkBlot.blotName = "mark";
  MarkBlot.tagName = "mark";
  Quill.register("formats/mark", MarkBlot);

  const quillRef = React.useRef<Quill | null>(null);
  const paragraphRef = React.useRef<HTMLDivElement | null>(null);
  const editorRef = React.useRef<HTMLDivElement | null>(null);
  // const containerRef = React.useRef<HTMLDivElement | null>(null);

  const textProps = React.useMemo(
    () => thisElement.props as TextProps,
    [thisElement]
  );

  const textContent = React.useMemo(() => {
    return textProps.ops.map((op) => op.insert).join("");
  }, [textProps.ops]);

  useUpdateEffect(() => {
    if (!isStatic) syncFromTextProps();
  }, [slideIndex, isStatic]);

  useUpdateEffect(() => {
    if (isStatic) syncFromTextProps();
  }, [textProps.ops]);

  React.useEffect(() => {
    if (paragraphRef.current && !quillRef.current) {
      quillRef.current = new Quill(paragraphRef.current, {
        theme: "snow", // or whatever theme you wish to use
        placeholder: "Type here...",
        readOnly: isStatic,
        formats: [
          "mark",
          "bold",
          "italic",
          "underline",
          "strike",
          "color",
          "font",
          "size",
          "background",
          "link",
          "align",
          "list",
        ],
        modules: {
          toolbar: false,
          // authorship: {
          //   enabled: true,
          //   authorId: "me",
          //   color: "red",
          // },
          // toolbar: isStatic ? false : `#slide-toolbar-placeholder`,
        },
      });
      quillRef.current.enable(!isStatic);
      syncFromTextProps();
      if (isStatic) return;

      if (thisElement.elementType !== "text") return;

      // if (delta.ops.length === 0) {
      Object.entries(thisElement.styleDefaults ?? {}).forEach(
        ([key, value]) => {
          quillRef.current?.format(key, value);
        }
      );
      // }
      // reset history on quillRef.current
      quillRef.current.history.clear();

      quillRef.current.on("text-change", handleTextChange);
      quillRef.current.on("selection-change", handleSelectionChange);

      return () => {
        quillRef.current?.off("text-change", handleTextChange);
        quillRef.current?.off("selection-change", handleSelectionChange);
        quillRef.current = null;
      };
    }
    return;
  }, []);

  React.useEffect(() => {
    if (paragraphRef.current) {
      editorRef.current = paragraphRef.current.querySelector(".ql-editor");

      if (editorRef.current) {
        if (textProps.valign === "middle") {
          editorRef.current.style.display = "flex";
          editorRef.current.style.flexDirection = "column";
          editorRef.current.style.justifyContent = "center";
        }
      }
    }
  }, [paragraphRef.current, textProps.valign, thisElement]);

  const syncFromTextProps = React.useCallback(() => {
    const delta = convertTextPropsToDelta(textProps.ops);

    try {
      if (quillRef.current) {
        // if (delta.ops.length === 0) return;
        return quillRef.current.setContents(delta);
      }
    } catch (e) {
      toast.error("Error loading text content.");
      const delta = new Delta();
      if (quillRef.current) {
        return quillRef.current.setContents(delta);
      }
    }
    return new Delta();
  }, [textProps.ops]);

  const updateFormats = React.useCallback(() => {
    const range = quillRef.current?.getSelection();
    if (range) {
      const currentFormats = quillRef.current?.getFormat(range);

      setCurrentFormats?.(currentFormats);
    }
  }, [setCurrentFormats]);

  const handleTextChange = React.useCallback(() => {
    if (isStatic) return;
    // Early return if quillRef.current is null
    if (quillRef.current === null) return;

    selectElement(thisElement.id);

    const contents = quillRef.current.getContents();
    const newTextOps = contents.ops;

    const newSlideElement: SlideElement = { ...thisElement };

    if (newSlideElement.elementType === "text") {
      // Use a type guard or improve typings to avoid 'as any'
      // if (isDeepEqual(newTextOps, newSlideElement.props.ops)) {
      //   return;
      // }

      newSlideElement.props.ops = newTextOps;

      onChangeElement(newSlideElement);
    }

    updateFormats();
  }, [onChangeElement, updateFormats, isStatic, thisElement]);

  const handleSelectionChange = React.useCallback(
    (range: RangeStatic) => {
      if (isStatic) return;

      if (range === null) {
        removeSelectedElement(thisElement.id);
      } else {
        setCurrentEditor?.(quillRef.current);
        selectElement(thisElement.id);
      }
      updateFormats();
    },
    [isStatic, thisElement]
  );

  return (
    <>
      {/* <style>
      {`
      #${idPrefix + thisElement.id} p {
        line-height: 1.2em;
      }
      `}
    </style> */}
      {thisElement.elementType === "text" ? (
        <div
          id={idPrefix + thisElement.id}
          ref={paragraphRef}
          hidden={isStatic && textContent.trim() === ""}
          style={{
            width: "100%",
            height: "100%",
            fontSize: thisElement.styleDefaults?.size
              ? thisElement.styleDefaults?.size
              : textProps.fontSize
              ? `${textProps.fontSize}pt`
              : undefined,
            fontFamily: thisElement.styleDefaults?.font,
            fontWeight: thisElement.styleDefaults?.bold ? "bold" : "normal",
            // lineHeight: "1.2em",
          }}
        />
      ) : null}
    </>
  );
}
