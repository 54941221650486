import {
  Flex,
  Link,
  Text,
  Button,
  TextField,
  Divider,
  Heading,
} from "@aws-amplify/ui-react";
import React from "react";
import { RaisedButton } from "../../../components";
import { FaGoogle } from "react-icons/fa6";
import {
  signInWithRedirect,
  SignInWithRedirectInput,
  signUp,
  SignUpInput,
  SignUpOutput,
} from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type Props = {
  username: string;
  password: string;
  onSetUsername: (username: string) => void;
  onSetPassword: (password: string) => void;
  onNextStep: (nextStep: SignUpOutput["nextStep"]) => void;
};

export function RegisterForm({
  username,
  password,
  onSetUsername,
  onSetPassword,
  onNextStep,
}: Props) {
  // const [formData, setFormData] = React.useState<SignUpInput>({
  //   username: "",
  //   password: "",
  // });

  const [confirmPassword, setConfirmPassword] = React.useState("");

  const navigate = useNavigate();

  const { mutateAsync: loginWithRedirect, isLoading: signingUpWithGoogle } =
    useMutation(
      ["SignUpWithRedirect"],
      async (args: SignInWithRedirectInput) => {
        return await signInWithRedirect(args);
      },
      {
        onSuccess: () => {},
      }
    );

  const { isError, error, isLoading, mutateAsync } = useMutation(
    ["SignUp"],
    async (args: SignUpInput) => {
      if (password !== confirmPassword)
        throw new Error("Passwords do not match");
      const { nextStep } = await signUp({
        ...args,
        options: {
          userAttributes: {
            email: username,
          },
          autoSignIn: true,
        },
      });
      if (nextStep) {
        onNextStep(nextStep);
      }
    },
    {}
  );

  return (
    <Flex
      as="form"
      direction={"column"}
      gap={"large"}
      paddingTop={"small"}
      onSubmit={async (e) => {
        e.preventDefault();
        await mutateAsync({ username, password });
      }}
    >
      <Heading level={5} textAlign={"center"}>
        Create Account
      </Heading>
      <Flex direction={"column"}>
        <Flex direction={"column"} gap={"small"}>
          <TextField
            isRequired
            label="Email"
            placeholder="Enter your email"
            type="email"
            value={username}
            onChange={(e) => {
              onSetUsername(e.target.value);
            }}
          ></TextField>
          <TextField
            isRequired
            label="Password"
            placeholder="Enter your password"
            type="password"
            value={password}
            onChange={(e) => {
              onSetPassword(e.target.value);
            }}
          ></TextField>
          <TextField
            isRequired
            label="Confirm Password"
            placeholder="Confirm your password"
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          ></TextField>

          <RaisedButton
            type="submit"
            isLoading={isLoading}
            loadingText="Creating Account..."
          >
            Create Account
          </RaisedButton>
          {isError ? (
            <Text variation="error">{(error as Error).message}</Text>
          ) : null}
        </Flex>
        <Flex alignItems={"center"}>
          <Divider borderColor={"neutral.40"} />
          <Text color={"neutral.40"} fontSize={"xs"}>
            <b>Or</b>
          </Text>
          <Divider borderColor={"neutral.40"} />
        </Flex>
        <Button
          gap={"small"}
          isLoading={signingUpWithGoogle}
          loadingText={"Signing Up with Google..."}
          onClick={() => loginWithRedirect({ provider: "Google" })}
        >
          <FaGoogle /> Sign Up with Google
        </Button>
        <Text fontWeight="normal" fontSize="small" textAlign={"center"}>
          Already have an account?{" "}
          <Link
            textDecoration={"underline"}
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            Log In
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
}
