import { useEffect, useState } from "react";

function getSavedValue<T extends object | string | undefined | number = string>(
  key: string
) {
  const itemString = localStorage.getItem(key);
  if (itemString === null || itemString === undefined) return;
  // try {

  try {
    const savedValue = JSON.parse(itemString);
    return savedValue as T;
  } catch {
    console.error(
      "Failed to parse locally stored value",
      itemString,
      "\nReturning stored value as is"
    );
    return itemString as T;
  }
}

export default function useLocalStorage<
  T extends object | string | undefined | number
>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  // if savedValue exists set as initial
  // else use given initalValue as initial
  const [value, setValue] = useState(() => {
    // called once at start
    const savedVal = getSavedValue<T>(key);
    return savedVal === undefined ? initialValue : savedVal;
  });

  // set to localStorage whenenver setValue() called
  useEffect(() => {
    localStorage.setItem(
      key,
      typeof value !== "string" ? JSON.stringify(value) : value
    );
  }, [value]);

  return [value, setValue];
}
