import * as React from "react";
import RaisedButton from "../../../../../components/RaisedButton";
import { LessonContext } from "../../LessonContextProvider";
import { SlidesContext } from "../../SlidesContextProvider";
import { createSlideObject } from "../../../util/createSlideObject";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../../../util/getTemplatedElements";

export interface IAddSlideTemplatedButtonProps {
  template: SlideElementsTemplate;
  children?: React.ReactNode;
}

export default function AddSlideTemplatedButton(
  props: IAddSlideTemplatedButtonProps
) {
  const { template } = props;

  const { lesson } = React.useContext(LessonContext);

  const { addSlide, slideIndex } = React.useContext(SlidesContext);

  return (
    <RaisedButton
      fontSize={"small"}
      onClick={() => {

        addSlide(
          createSlideObject(
            lesson.id,
            getTemplatedElements(template, {
              headerText: "",
              bodyText: "",
              imagePath: "",
            })
          ),
          slideIndex + 1
        );
      }}
    >
      {props.children}
    </RaisedButton>
  );
}
