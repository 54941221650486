import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";
import ReactGA from "react-ga4";
import { useCookie } from "./useCookie";
import useEffectOnce from "./useEffectOnce";
import posthog from "posthog-js";

const cookieName = "cookie-consent";
const storageName = "modal-closed";

type ModalState =
  | {
      closed: false;
    }
  | {
      closed: true;
      expiration: number;
    };

export function useCookieConsent() {
  const [modalState, setModalState] = useLocalStorage<ModalState | undefined>(storageName, undefined);

  const { getCookie, setCookie, deleteAllCookies } = useCookie();

  useEffect(() => {
    if (getCookie(cookieName)) {
      ReactGA.initialize("G-LT8GNF036J");


      posthog.init("phc_eOeDyxZwX8JAdZZjITUrOuTyY4Uo7ez2BmJpEoDSuyB", {
        api_host: "https://app.posthog.com",
      });

    }
  }, [modalState]);

  useEffectOnce(() => {
    if (!getCookie(cookieName)) {
      deleteAllCookies();

      if (modalState?.closed && modalState.expiration < Date.now()) {
        setModalState({
          closed: false,
        });
      }
    }
  });

  function submitConsent(consent: boolean) {
    if (consent) {
      setCookie(cookieName, "Yes", 30);
    }
    setModalState({
      closed: true,
      expiration: Date.now() + 1000 * 60 * 60 * 24 * 30,
    });
  }

  return {
    modalState,
    cookieConsent: getCookie(cookieName),
    submitConsent,
  };
}
