import {
  PollyClient,
  SynthesizeSpeechCommand,
  SynthesizeSpeechCommandInput,
} from "@aws-sdk/client-polly";
import { fetchAuthSession } from "aws-amplify/auth";
import { Speed } from "../types";
import { getLanguageCodeAndVoice } from "../util";
import { LanguageOption } from "../../translations";

export async function synthesizeSpeechMarks(
  text: string,
  lang: LanguageOption,
  speed: Speed
) {
  // a client can be shared by different commands.

  const credentials = (await fetchAuthSession()).credentials; // current users credentials

  const client = new PollyClient({ region: "us-east-1", credentials });

  const { voiceId, languageCode, engine } = getLanguageCodeAndVoice(lang);
  const params: SynthesizeSpeechCommandInput = {
    OutputFormat: "json",
    Text: `<speak><prosody rate="${speed}">${text}</prosody></speak>`,
    TextType: "ssml",
    SpeechMarkTypes: ["word"],

    VoiceId: voiceId,
    Engine: engine,
    LanguageCode: languageCode,
  };
  const command = new SynthesizeSpeechCommand(params);

  try {
    const data = await client.send(command);
    if (!data.AudioStream) {
      throw new Error("Failed to synthesize speech.");
    }
    return data.AudioStream;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to synthesize speech.");
  }
}
