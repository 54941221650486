import * as React from "react";
import { Slide, SlideElement } from "../types/slideTypes";
import { createContext, useMemo } from "react";
import { v4 } from "uuid";
import { LessonContext } from "./LessonContextProvider";
import { useOptimisticDebounce } from "../../../hooks/useOptimisticStateDebounce";
import { updateSlide } from "../services/updateSlide";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../util/getTemplatedElements";
import { deleteSlide } from "../services/deleteSlide";
import { createSlide } from "../services/createSlide";
import { createSlideObject } from "../util/createSlideObject";
import { isDeepEqual } from "../../../util/isDeepEqual";
import { useScreenReaderContext } from "../../accessibility";

interface ISlidesContext {
  slideCurrent: Slide;
  slides: Slide[];
  slideIndex: number;
  slidePreview: Slide | undefined;
  setSlideIndex: (value: number) => void;
  addSlide: (slide: Slide, index: number) => void;
  setSlideElement: (id: string, element: SlideElement) => void;
  setSlidePreview: (slide: Slide | undefined) => void;
  setBackgroundColor: (color: string | undefined) => void;
  removeSlide: (slideId: string) => void;
  moveSlide: (startIndex: number, endIndex: number) => void;
  setSlides: (slides: Slide[]) => void;
  nextSlide: () => void;
  prevSlide: () => void;
  // slidesUndo: () => void;
  // slidesRedo: () => void;
}

export const SlidesContext = createContext<ISlidesContext>(
  {} as ISlidesContext
);

export interface ISlidesContextProviderProps {
  children: React.ReactNode;
  slidesServer: Slide[] | undefined;
}

export default function SlidesContextProvider(
  props: ISlidesContextProviderProps
) {
  const { children, slidesServer } = props;

  const [slideIndex, setSlideIndex] = React.useState<number>(0);
  const [slidePreview, setSlidePreview] = React.useState<Slide>();
  // const [newestSlide, setNewestSlide] = React.useState<Slide | undefined>();
  // const { addUpdate, undo, redo } = useUndoRedoArray<Slide>();
  const { stop } = useScreenReaderContext();
  const { lesson } = React.useContext(LessonContext);

  const [slides, setSlides] = React.useState(
    slidesServer?.length === 0 || !slidesServer
      ? ([
          {
            lessonId: lesson.id,
            id: "slide_" + v4(),
            orderIndex: 0,
            elements: getTemplatedElements(SlideElementsTemplate.ImageRight, {
              headerText: "",
              bodyText: "",
              imagePath: "",
            }),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        ] as Slide[])
      : slidesServer
  );

  const pushSlideChanges = React.useCallback(
    async (newSlides: Slide[]) => {
      // find the elements of "newSlides" that are different from the elements in "slides"
      const changedSlides = newSlides.filter((newSlide) => {
        const oldSlide = slides.find((slide) => slide.id === newSlide.id);
        if (oldSlide === undefined) return false; // if oldSlide is undefined, then it is a new slide (not changed
        return isDeepEqual(oldSlide, newSlide) || oldSlide !== newSlide;
      });

      // update the changed slides
      await Promise.all(
        changedSlides.map(async (slide) => {
          const { id, lessonId, createdAt, updatedAt, ...updateSlideObject } =
            slide;
          await updateSlide(lesson.id, slide.id, updateSlideObject);
        })
      );

      // find all elements of "slides" that are not in "newSlides"
      const deletedSlides = slides.filter(
        (slide) => !newSlides.find((newSlide) => newSlide.id === slide.id)
      );

      // delete the deleted slides
      await Promise.all(
        deletedSlides.map(async (slide) => {
          await deleteSlide(lesson.id, slide.id);
        })
      );

      // find all elements of "newSlides" that are not in "slides"
      const addedSlides = newSlides.filter(
        (newSlide) => !slides.find((slide) => slide.id === newSlide.id)
      );
      // add the added slides
      await Promise.all(
        addedSlides.map(async (slide) => {
          await createSlide(slide);
        })
      );

      setSlides([...newSlides]);
    },
    [slides, lesson.id]
  );

  const [optimisticSlides, setOptimisticSlides] = useOptimisticDebounce<
    Slide[]
  >(slides, 500, pushSlideChanges);

  // React.useEffect(() => {

  // }, [slides]);

  // React.useEffect(() => {

  // }, [slides[0].elements[0].props]);

  const slideCurrent = useMemo(() => {
    let index = slideIndex;
    if (index >= optimisticSlides.length) {
      index = optimisticSlides.length - 1;
    }
    if (index < 0) {
      index = 0;
    }
    return optimisticSlides[index];
  }, [slideIndex, optimisticSlides[slideIndex]]);

  const sortedSlides = useMemo(() => {
    //sort optimistedSlides by acsending order index
    return optimisticSlides.sort((a, b) => a.orderIndex - b.orderIndex);
  }, [optimisticSlides]);

  // useEffectOnce(() => {
  //   addUpdate([...slides]);
  // });

  const addSlide = React.useCallback(
    (slide: Slide, index: number) => {
      setOptimisticSlides((prev) => {
        let slides: Slide[] = [...prev];

        const newSlide = { ...slide };
        newSlide.orderIndex = index;
        slides.splice(index, 0, newSlide);
        slides = fixSlideOrderIndexes(slides);

        return slides;
      });
      // addUpdate([...slides]);

      setSlideIndex(index);
    },
    [setOptimisticSlides]
  );

  const removeSlide = React.useCallback(
    (slideId: string) => {
      setOptimisticSlides((prev) => {
        let slides: Slide[] = [...prev];
        const index = slides.findIndex((slide) => slide.id === slideId);

        slides.splice(index, 1);
        // fix slide orderIndexes
        slides = fixSlideOrderIndexes(slides);
        if (slides.length === 0) {
          slides = [createSlideObject(lesson.id)];
        }
        if (slideIndex >= slides.length) {
          setSlideIndex(slides.length - 1);
        }
        return slides;
      });

      // addUpdate(slides);
    },
    [setOptimisticSlides, lesson.id, slideIndex]
  );

  const fixSlideOrderIndexes = React.useCallback((slides: Slide[]) => {
    // fix slide orderIndexes
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].orderIndex === i) continue;
      slides[i].orderIndex = i;
    }
    return slides;
  }, []);

  // React.useEffect(() => {

  // }, [optimisticSlides]);

  React.useEffect(() => {
    setSlidePreview(undefined);
  }, [slideIndex]);

  const setSlideElement = React.useCallback(
    (id: string, element: SlideElement) => {
      let slides: Slide[] = [];

      setOptimisticSlides((prev) => {
        const index = prev.findIndex((slide) => slide.id === id);
        if (index === -1) return prev;

        slides = [...prev];
        const slide = { ...slides[index] };

        const elements: SlideElement[] = [...slide.elements];

        const elementIndex = elements.findIndex((e) => e.id === element.id);
        if (elementIndex === -1) return prev;

        elements[elementIndex] = { ...element };

        slide.elements = elements;

        slides[index] = slide;

        return slides;
      });

      // addUpdate(slides);
    },
    [setOptimisticSlides]
  );

  const setBackgroundColor = (color: string | undefined) => {
    let newSlidesState: Slide[] = [];
    setOptimisticSlides((prev) => {
      // replace element at slideIndex with new data
      newSlidesState = [...prev];
      const newSlide = { ...newSlidesState[slideIndex] };
      newSlide.background = { color };

      newSlidesState = [
        ...newSlidesState.slice(0, slideIndex),
        newSlide,
        ...newSlidesState.slice(slideIndex + 1),
      ];
      return newSlidesState;
    });

    // addUpdate(newSlidesState);
  };

  const moveSlide = (startIndex: number, endIndex: number) => {
    let slides: Slide[] = [];
    setOptimisticSlides((prev) => {
      slides = [...prev];
      const slide = slides[startIndex];
      if (startIndex < endIndex) {
        slides.splice(endIndex, 0, slide);
        slides.splice(startIndex, 1);
      } else {
        slides.splice(startIndex, 1);
        slides.splice(endIndex, 0, slide);
      }
      // fix slide orderIndexes
      slides = fixSlideOrderIndexes(slides);
      return slides;
    });

    // addUpdate(slides);
  };

  // const slidesUndo = () => {
  //   const undoSlides = undo();
  //   if (undoSlides === undefined) return;
  //   const slides = [...undoSlides];

  //   setOptimisticSlides(slides);
  // };

  // const slidesRedo = () => {
  //   const redoSlides = redo();
  //   if (redoSlides === undefined) return;
  //   const slides = [...redoSlides];
  //   setOptimisticSlides(slides);
  // };

  const nextSlide = React.useCallback(() => {
    if (slideIndex + 1 < optimisticSlides.length) {
      setSlideIndex(slideIndex + 1);
    }
    stop();
  }, [slideIndex, optimisticSlides, stop]);

  const prevSlide = React.useCallback(() => {
    if (slideIndex - 1 >= 0) {
      setSlideIndex(slideIndex - 1);
    }
    stop();
  }, [slideIndex, stop]);

  React.useEffect(() => {
    // check that slideIndex is within bounds
    if (slideIndex >= optimisticSlides.length) {
      setSlideIndex(optimisticSlides.length - 1);
    }
  }, [slideIndex, optimisticSlides]);

  return (
    <SlidesContext.Provider
      value={{
        slideCurrent,
        slides: sortedSlides,
        setSlides: setOptimisticSlides,
        slideIndex,
        setSlideIndex,
        slidePreview,
        addSlide,
        setSlideElement,
        setBackgroundColor,
        setSlidePreview,
        nextSlide,
        removeSlide,
        moveSlide,
        // slidesUndo,
        // slidesRedo,
        prevSlide,
      }}
    >
      {children}
    </SlidesContext.Provider>
  );
}
