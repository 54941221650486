import {
  Flex,
  Text,
  TextAreaField,
  TextAreaProps,
} from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../../../components/RaisedButton";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import useChatStream from "../../../hooks/useChatStream";
import { LessonContext } from "./LessonContextProvider";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { useImperativeHandle, useMemo } from "react";
import { toast } from "react-toastify";

const characterLimit = 2500;

export type LessonPlanFormTextAreaRef = {
  generateContent: () => void;
};

export interface ILessonPlanFormTextAreaProps
  extends Omit<TextAreaProps, "label" | "ref"> {
  value: string;
  setValue: (value: string) => void;
  label?: React.ReactNode;
  type: "overview" | "objectives" | "coverage" | "vocabulary";
  inputObject: Object;
}

export const LessonPlanFormTextArea = React.forwardRef<
  LessonPlanFormTextAreaRef,
  ILessonPlanFormTextAreaProps
>((props, ref) => {
  const { label, setValue, value, type, inputObject, ...textAreaProps } = props;

  const { lesson, isOwner, outputLang } = React.useContext(LessonContext);

  const [chatStream, setChatStream] = React.useState<string>("");

  // React.useEffect(() => {

  // }, [inputObject]);

  const { connect, connecting, connected, disconnect } = useChatStream({
    type,
    inputObject,
    language: outputLang,
    onOpen: () => {
      setChatStream("");
    },
    onChat: (stream) => {
      setChatStream((prev) => prev.concat(stream));
    },
    onError: (err) => {
      console.error("There was an error", err);
    },
  });

  const output = useMemo(
    () => chatStream.split("\n###\n")[1]?.replace(/^\s+/g, "") ?? "",
    [chatStream]
  );

  const numCharacters = useMemo(() => {
    const string = value;
    return string.length;
  }, [value]);

  const loadingMessage = useMemo(() => {
    if (connecting) return "Connecting...";
    else if (connected && output === "") return "Thinking...";
    else if (connected && output !== "") return "Doing...";
    else return "";
  }, [connecting, connected, output]);

  useImperativeHandle(
    ref,
    () => ({
      generateContent: () => {
        connect();
      },
    }),
    [connect]
  );

  useUpdateEffect(() => {
    setValue(output);
  }, [output]);

  useUpdateEffect(() => {
    // constrain value to character limit
    if (value.length > characterLimit) {
      setValue(value.slice(0, characterLimit));
      disconnect();
    }
  }, [value]);

  return (
    <TextAreaField
      size="small"
      variation="quiet"
      backgroundColor={"background.secondary"}
      borderRadius={"medium medium 0 0"}
      label={
        <Flex
          justifyContent={"space-between"}
          alignItems={"end"}
          padding={"small"}
          paddingBottom={"xs"}
          paddingTop={"xs"}
        >
          {label}
          <Flex alignItems={"end"}>
            <Text
              fontSize={"small"}
              variation={"tertiary"}
            >{`${numCharacters}/${characterLimit}`}</Text>
            {isOwner && (
              <RaisedButton
                size="small"
                fontSize={"xs"}
                backgroundColor={"purple.60"}
                color={"white"}
                isLoading={connecting || connected}
                loadingText={loadingMessage}
                onClick={() => {
                  if (lesson.topic === "" || lesson.gradeLevel === "")
                    toast.error("Please enter a topic, and grade level.");
                  else connect();
                }}
                data-attr="create-lesson-plan"
                data-ph-capture-attribute-type={"lesson-" + type}
                data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
                data-ph-capture-attribute-grade-level={lesson.gradeLevel}
                data-ph-capture-attribute-reading-level={lesson.readingLevel}
                data-ph-capture-attribute-language={lesson.lang}
              >
                <SparkleIcon />
              </RaisedButton>
            )}
          </Flex>
        </Flex>
      }
      onChange={(e) => setValue(e.target.value)}
      value={value}
      {...textAreaProps}
    />
  );
});
