import { v4 } from "uuid";
import { S3ObjectProtected } from "../../../API";
import { getIdentityId } from "../../game/util";
import { toast } from "react-toastify";
import { uploadS3Item } from "../../../services/s3/uploadS3Item";
import { getS3Url } from "../../../services/s3/getS3Url";

export async function uploadProtectedImage(
  file: File
): Promise<{ image: S3ObjectProtected; url: string | URL } | undefined> {
  try {
    await validateImage(file);
    const fileName = v4() + ".jpg";
    const identityId = await getIdentityId();
    await uploadS3Item({
      fileName,
      data: file,
      level: "protected",
      options: {
        contentType: "image/*", // contentType is optional
      },
    });
    // get image from s3
    const { url } = await getS3Url({
      version: 1,
      key: fileName,
      level: "protected",
      identityId,
    }).then((res) => {
      return res;
    });

    const image: S3ObjectProtected = {
      __typename: "S3ObjectProtected",
      key: fileName,
      identityId,
    };

    return { image, url };
  } catch (error: any) {
    toast.error(error.message);
    return;
  }
}

// export async function uploadProtectedImage(
//   file: File
// ): Promise<{ image: S3ObjectProtected; url: string } | undefined> {
//   try {
//     const maxSize = 500; // test

//     if (file.size > maxSize) {
//       throw new Error("File is too large, please select a file less than 5MB.");
//     }

//     const results = new Promise((resolve, reject) => {
//       const img = new Image();
//       img.src = URL.createObjectURL(file);
//       img.onload = async function () {
//         const width = img.naturalWidth;
//         const height = img.naturalHeight;
//         const megapixels = (width * height) / 1000000;

//         if (megapixels > 25) {
//           reject(
//             "Image is too large, please select an image less than 25 megapixels."
//           );
//         } else {
//           const result = await Storage.put(v4() + ".jpg", file, {
//             level: "protected",
//             contentType: "image/*", // contentType is optional
//           });

//           const key = result.key;
//           // get image from s3
//           const url = await getUrl(key, {
//             level: "protected",
//           });

//           const identityId = await getIdentityId();
//           const image: S3ObjectProtected = {
//             __typename: "S3ObjectProtected",
//             key,
//             identityId,
//           };
//           resolve({ image, url });
//         }
//       };
//       img.onerror = function () {
//         reject("Invalid image file.");
//       };
//     });
//   } catch (error: any) {

//     toast.error(error.message);
//   }
// }

const validateImage = async (file: File) => {
  const results = await new Promise((resolve, reject) => {
    const maxSize = 50000000;

    if (file.size > maxSize) {
      reject("File is too large, please select a file less than 50MB.");
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const megapixels = (width * height) / 1000000;

      if (megapixels > 25) {
        reject(
          "Image is too large, please select an image less than 25 megapixels."
        );
      } else {
        resolve("Image is valid.");
      }
    };
    img.onerror = function () {
      reject("Invalid image file.");
    };
  }).catch((error) => {
    throw new Error(error);
  });
  return results;
};
