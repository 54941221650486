import * as React from "react";
import SlideElementView from "./SlideElement";

import { SlideElementsContext } from "../SlideElementsContextProvider";

export interface ISlideElementCollectionProps {}

export default function SlideElementCollection(
  props: ISlideElementCollectionProps
) {
  const {} = props;

  const { elements } = React.useContext(SlideElementsContext);

  return (
    <>
      {elements.map((slideElement, index) => (
        <SlideElementView key={index} slideElement={slideElement} />
      ))}
    </>
  );
}
