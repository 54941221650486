import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { querySlides } from "../../features/lesson/services/querySlides";
import { createSlide } from "../../features/lesson/services/createSlide";
import { createSlideObjectDefault } from "../../features/lesson/util/createSlideObject";
import { Slide } from "../../features/lesson/types/slideTypes";

export default function useSlidesQuery(
  lessonId: string | undefined,
  options?: UseQueryOptions<
    Slide[],
    unknown,
    Slide[],
    [string, string | undefined, string]
  >
) {
  const { data: slides, ...restQuery } = useQuery(
    ["lesson", lessonId, "slides"],
    async () => {
      try {
        const slides = await querySlides(lessonId as string);
        if (slides?.length === 0) {
          const slide = await createSlide(
            createSlideObjectDefault(lessonId as string)
          );
          return [slide as Slide];
        }
        return slides;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: lessonId !== undefined,
      ...options,
    }
  );

  return { slides, ...restQuery };
}
