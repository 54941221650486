import { v4 } from "uuid";
import { getIdentityId } from "../../game/util";
import { getS3Url } from "../../../services/s3/getS3Url";
import { uploadS3Item } from "../../../services/s3/uploadS3Item";

export async function uploadProtectedImageFromURL(
  src: string,
  onUpload?: (
    image: { key: string; identityId: string; alt: string },
    url: string | URL
  ) => void
): Promise<
  | {
      image: { key: string; identityId: string; alt: string };
      url: string | URL;
    }
  | undefined
> {
  try {
    var imgExt = getUrlExtension(src);
    const fileName = v4() + imgExt;
    const response = await fetch(src);
    const blob = await response.blob();
    const file = new File([blob], fileName, {
      type: blob.type,
    });

    const result = await uploadS3Item({
      level: "protected",
      fileName,
      data: file,
      options: {
        contentType: "image/*", // contentType is optional
      },
    });

    const path = (await result).path;
    // get image from s3
    const { url } = await getS3Url({ version: 2, path: path });

    const identityId = await getIdentityId();
    const image = {
      key: fileName,
      identityId,
      alt: `Image named ${fileName}`,
    };

    onUpload?.(image, url);
    return { image, url };
  } catch (error) {
    return;
  }
}

const getUrlExtension = (url: string | any) => {
  return "." + url.split(/[#?]/)[0].split(".").pop().trim();
};
