import { Flex, Grid, Heading, Link, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { Container } from "react-bootstrap";
import TopNavbar from "../layouts/TopNavbar";
import { PageIndex, PageTitle } from "../util/SEO";
import Footer from "../layouts/Footer";
import { S3Image } from "../components/S3Image";
import useEffectOnce from "../hooks/useEffectOnce";

export default function NotFoundPage() {

  useEffectOnce(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        entry.target.classList.toggle("show", entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target);
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  });

  PageTitle("Page Not Found | Gibbly");
  PageIndex("noindex");

  return (
    <>
      <Flex
        minHeight="100vh"
        direction={"column"}
        justifyContent={"space-between"}
      >
        <TopNavbar useAuthentication={false} />
        <Container>
          <View as="section" padding={"large"}>
            <Flex justifyContent={"center"}>
              <S3Image
                bucket="gibbly-homepage-assets"
                s3key={"images/404-not-found.png"}
                alt="404 Not Found Image"
                objectFit={"cover"}
                height="20rem"
                width="20rem"
              />
            </Flex>
            <Flex justifyContent={"center"} textAlign="center">
              <Heading level={5}>Sorry, we couldn't find that page...</Heading>
            </Flex>

            <Flex
              textAlign={"center"}
              justifyContent={"center"}
              padding="xl"
              direction={"column"}
            >
              <Text>Were you looking for one of these pages?</Text>
              <Container>
                <Flex justifyContent={"center"}>
                  <Grid
                    templateColumns={{ base: "1fr", large: "1fr 1fr 1fr" }}
                    templateRows="1fr"
                    gap="medium"
                    columnGap={"xxxl"}
                    padding={"small"}
                    paddingLeft={"xxxl"}
                    paddingRight={"xxxl"}
                    textAlign={{ base: "center", large: "left" }}
                  >
                    <Link
                      fontWeight={"bold"}
                      color={"purple.100"}
                      href="/"
                    >
                      Home
                    </Link>
                    <Link
                      fontWeight={"bold"}
                      color={"purple.100"}
                      href="/dashboard/library/"
                    >
                      Library
                    </Link>
                    <Link
                      fontWeight={"bold"}
                      color={"purple.100"}
                      href="/dashboard/discover/"
                    >
                      Discover
                    </Link>
                  </Grid>
                </Flex>
              </Container>
            </Flex>
          </View>
        </Container>

        <Footer />
      </Flex>
    </>
  );
}
