import React, { useCallback, useMemo, useState } from "react";

import { Quiz, S3ObjectProtected } from "../../../API";

import {
  Flex,
  Card,
  Authenticator,
  Tabs,
  Text,
  View,
  CheckboxField,
} from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../../../util/SEO";

import TopicForm from "./GenerateQuiz.TopicForm";
import YoutubeForm from "./GenerateQuiz.YouTubeForm";
import TitleGenerator, { TitleGeneratorHandle } from "./TitleGenerator";
import QuestionGenerator, {
  FixedQuestion,
  QuestionGeneratorHandle,
} from "./QuestionGenerator";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import useUserQuery from "../../../hooks/useUserQuery";
import GibblyLoader from "../../../components/GibblyLoader";
import {
  ImageComponent,
  ImageComponentHandle,
} from "../../../components/ImageComponent";
import uploadGeneratedQuestions from "../util/uploadGeneratedQuestions";
import RaisedButton from "../../../components/RaisedButton";
import ArrowRightIcon from "../../../components/icons/ArrowRightIcon";
import useArray from "../../../hooks/useArray";
import { useNavigate } from "react-router-dom";
import createQuiz from "../../quiz/utils/createQuiz";
import { toast } from "react-toastify";
import { useAutoPickImageMutation } from "../../../hooks/useAutoPickImageMutation";
import InfoIcon from "../../../components/icons/InfoIcon";
import DocumentForm from "./GenerateQuiz.DocumentForm";
import PasteForm from "./GenerateQuiz.PasteForm";
import { FaClipboard, FaFile, FaLightbulb, FaYoutube } from "react-icons/fa";
import Media from "react-media";
import ModelToggle from "./ModelToggle";

export type TopicPrompt = {
  topic: string;
  numberOfQuestions: string;
  gradeLevel: string;
  fileId?: string;
  instructions?: string;
  lang: string;
};

type TopicInput = {
  type: "mixed";
  prompt: TopicPrompt;
};
type PasteInput = {
  type: "paste-mixed";
  prompt: TopicPrompt;
};

type YouTubeInput = {
  type: "yt-mixed";
  prompt: TopicPrompt;
};

type DocInput = {
  type: "doc-mixed";
  prompt: TopicPrompt;
};

// enum GenType {
//   Topic = "topic",
//   Paste = "paste",
//   YouTube = "youtube",
//   Document = "document",
// }

type GenModelCombo = {
  title: "quiz-title" | "quiz-title-yt" | "quiz-title-doc" | "quiz-title-paste";
  question: "mixed" | "yt-mixed" | "doc-mixed" | "paste-mixed";
};

export type GenerationInput = TopicInput | YouTubeInput | DocInput | PasteInput;

export default function GenerateQuizForm({
  quiz,
  onSuccess,
}: {
  quiz?: Quiz;
  onSuccess?: () => void;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  const navigate = useNavigate();

  const [genInput, setGenInput] = useState<GenerationInput>({
    type: "mixed",
    prompt: {
      topic: "",
      numberOfQuestions: "20",
      gradeLevel: "",
      lang: "en",
    },
  });

  const [title, setTitle] = useState("");
  const { array: questions, set: setQuestions } = useArray<FixedQuestion>([]);

  const {
    array: keepArray,
    update: updateKeep,
    clear: clearKeepArray,
    push: pushKeep,
  } = useArray<boolean>([]);

  const [submitting, setSubmitting] = React.useState(false);
  const [autoPickImages, setAutoPickImages] = React.useState(false);

  const [quizImage, setQuizImage] = useState<S3ObjectProtected | null>(null);
  const quizImageRef = React.useRef<ImageComponentHandle>(null);

  const titleGenerator = React.useRef<TitleGeneratorHandle>(null);
  const questionGenerator = React.useRef<QuestionGeneratorHandle>(null);

  const [genType, setGenType] = useState<
    string | "topic" | "paste" | "youtube" | "document"
  >("topic");

  const genModelCombo: GenModelCombo = useMemo(() => {
    switch (genType) {
      case "topic":
        return {
          title: "quiz-title",
          question: "mixed",
        };
      case "paste":
        return {
          title: "quiz-title-paste",
          question: "paste-mixed",
        };
      case "youtube":
        return {
          title: "quiz-title-yt",
          question: "yt-mixed",
        };
      case "document":
        return {
          title: "quiz-title-doc",
          question: "doc-mixed",
        };
      default:
        return {
          title: "quiz-title",
          question: "mixed",
        };
    }
  }, [genType]);

  const onGenerate = useCallback(async (input: GenerationInput) => {
    setGenInput(input);
  }, []);

  const generate = useCallback(async () => {
    if (title === "") await titleGenerator.current?.generateTitle();
    await questionGenerator.current?.generateQuestions();
    if (quizImage === null) await quizImageRef.current?.autoPickImage();
  }, [titleGenerator, questionGenerator, title, quizImage]);

  useUpdateEffect(() => {
    generate();
  }, [genInput.prompt]);

  const [titleLoading, setTitleLoading] = useState(false);
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const loading = useMemo(
    () => titleLoading || questionsLoading,
    [titleLoading, questionsLoading]
  );

  const { tokenBalanceString, isLoading } = useUserQuery();

  const { autoPickImage } = useAutoPickImageMutation();

  const questionsSubmitCallback = React.useCallback(async () => {
    const keptQuestions = questions.filter((_, i) => keepArray[i]);
    const questionPromises = keptQuestions.map(async (q) => {
      try {
        if (!autoPickImages) return q;
        let newQuizQuestionObject: FixedQuestion = q;
        await autoPickImage({
          prompt: q.text ?? "",
          onUpload: (image) => {
            newQuizQuestionObject = {
              ...q,
              image: {
                key: image.key,
                identityId: image.identityId,
                alt: image.alt ?? "",
              },
            };
          },
        });

        return newQuizQuestionObject;
      } catch (err) {
        console.error(err);
        return q;
      }
    });

    const finalQuestions = await Promise.all(questionPromises);

    if (quiz) {
      try {
        await uploadGeneratedQuestions(quiz, finalQuestions);
        onSuccess?.();
      } catch (err) {
        toast.error("Error generating questions. Please try again.");
      }
    } else {
      try {
        const quiz = await createQuiz({
          title,
          description: "",
          tags: [],
          image: {
            key: quizImage?.key ?? "",
            identityId: quizImage?.identityId ?? "",
          },
          lang: genInput.prompt.lang,
        });

        await uploadGeneratedQuestions(quiz, finalQuestions);

        navigate("/quiz/" + quiz?.id);
        onSuccess?.();
      } catch (err) {
        toast.error("Error generating quiz. Please try again.");
      }
    }
  }, [
    questions,
    title,
    keepArray,
    quiz,
    onSuccess,
    quizImage,
    autoPickImages,
    genInput.prompt.lang,
  ]);

  return (
    <Authenticator>
      <Flex direction={"column"}>
        <Card backgroundColor={"background.tertiary"}>
          <Flex direction={"column"}>
            <Tabs.Container
              spacing="equal"
              gap={"0"}
              value={genType}
              onValueChange={(v) => {
                setGenType(v);
              }}
            >
              <Tabs.List>
                <Tabs.Item value="topic">
                  <Text
                    whiteSpace={"nowrap"}
                    textAlign={"center"}
                    fontSize={{ base: "medium", small: "xs", large: "medium" }}
                  >
                    <FaLightbulb
                      color="#FF7B00"
                      style={{ marginRight: "5px" }}
                    />

                    <Media queries={{ small: { maxWidth: 480 } }}>
                      {(matches) => (matches.small ? <></> : <>Topic</>)}
                    </Media>
                  </Text>
                </Tabs.Item>
                <Tabs.Item value="paste">
                  <Text
                    whiteSpace={"nowrap"}
                    textAlign={"center"}
                    fontSize={{ base: "medium", small: "xs", large: "medium" }}
                  >
                    <FaClipboard
                      color="#1A90FF"
                      style={{ marginRight: "5px" }}
                    />

                    <Media queries={{ small: { maxWidth: 480 } }}>
                      {(matches) => (matches.small ? <></> : <>Paste</>)}
                    </Media>
                  </Text>
                </Tabs.Item>
                <Tabs.Item value="youtube">
                  <Text
                    whiteSpace={"nowrap"}
                    textAlign={"center"}
                    fontSize={{ base: "medium", small: "xs", large: "medium" }}
                  >
                    <FaYoutube color="#DE3721" style={{ marginRight: "5px" }} />

                    <Media queries={{ small: { maxWidth: 480 } }}>
                      {(matches) => (matches.small ? <></> : <>YouTube</>)}
                    </Media>
                  </Text>
                </Tabs.Item>
                <Tabs.Item value="document">
                  <Text
                    whiteSpace={"nowrap"}
                    textAlign={"center"}
                    fontSize={{ base: "medium", small: "xs", large: "medium" }}
                  >
                    <FaFile color="#9000FF" style={{ marginRight: "5px" }} />

                    <Media queries={{ small: { maxWidth: 480 } }}>
                      {(matches) => (matches.small ? <></> : <>Document</>)}
                    </Media>
                  </Text>
                </Tabs.Item>
              </Tabs.List>
              <Flex width={"100%"} justifyContent={"end"} paddingTop={"small"}>
                <ModelToggle />
              </Flex>
              <Tabs.Panel value={"topic"}>
                <TopicForm onGenerate={onGenerate} loading={loading} />
              </Tabs.Panel>
              <Tabs.Panel value={"paste"}>
                <PasteForm onGenerate={onGenerate} loading={loading} />
              </Tabs.Panel>
              <Tabs.Panel value={"youtube"}>
                <YoutubeForm onGenerate={onGenerate} loading={loading} />
              </Tabs.Panel>
              <Tabs.Panel value={"document"}>
                <DocumentForm onGenerate={onGenerate} loading={loading} />
              </Tabs.Panel>
            </Tabs.Container>

            <Flex direction={"column"}>
              {!quiz && (
                <Flex direction={{ base: "column", large: "row" }}>
                  <View flex={1} hidden={title === ""}>
                    <ImageComponent
                      ref={quizImageRef}
                      alt={undefined}
                      aspectRatio="16/9"
                      width={"100%"}
                      image={quizImage}
                      canEdit={true}
                      autoPickPrompt={
                        title === "" ? genInput.prompt.topic : title
                      }
                      updateImage={async ({ image }) => {
                        setQuizImage(image);
                      }}
                    />
                  </View>
                  <View flex={2}>
                    <TitleGenerator
                      ref={titleGenerator}
                      gradeLevel={genInput.prompt.gradeLevel}
                      title={title}
                      setTitle={setTitle}
                      setTitleLoading={setTitleLoading}
                      type={genModelCombo.title}
                      topic={genInput.prompt.topic}
                      lang={genInput.prompt.lang}
                    />
                  </View>
                </Flex>
              )}
              {questions.length === 0 || loading ? null : (
                <Flex
                  width={"100%"}
                  justifyContent={"center"}
                  gap={"large"}
                  wrap={"wrap"}
                >
                  <Flex justifyContent={"center"}>
                    <CheckboxField
                      label={"QuickPic"}
                      name={"auto-pick-images"}
                      alignSelf={"center"}
                      value={"yes"}
                      checked={autoPickImages}
                      onChange={(e) => setAutoPickImages(e.target.checked)}
                    />
                    <InfoIcon message="Auto-select images for each quiz question" />
                  </Flex>
                  <Flex justifyContent={"center"}>
                    <RaisedButton
                      gap={"small"}
                      variation="primary"
                      backgroundColor={"#1a90ff"}
                      isLoading={submitting}
                      onClick={async () => {
                        setSubmitting(true);
                        await questionsSubmitCallback();
                        setSubmitting(false);
                      }}
                      data-attr="done-generating-quiz"
                    >
                      Add to Quiz <ArrowRightIcon />
                    </RaisedButton>
                  </Flex>
                </Flex>
              )}
              <QuestionGenerator
                ref={questionGenerator}
                type={genModelCombo.question}
                lang={genInput.prompt.lang}
                // topic={genInput.prompt.topic}
                // gradeLevel={genInput.prompt.gradeLevel}
                // numberOfQuestions={genInput.prompt.numberOfQuestions}
                input={genInput.prompt}
                setQuestionsLoading={setQuestionsLoading}
                questions={questions}
                setQuestions={setQuestions}
                keepArray={keepArray}
                pushKeep={pushKeep}
                updateKeep={updateKeep}
                clearKeepArray={clearKeepArray}
              />

              {questions.length === 0 || loading ? null : (
                <Flex
                  width={"100%"}
                  justifyContent={"center"}
                  gap={"large"}
                  wrap={"wrap"}
                >
                  <Flex justifyContent={"center"}>
                    <CheckboxField
                      label={"QuickPic"}
                      name={"auto-pick-images"}
                      alignSelf={"center"}
                      value={"yes"}
                      checked={autoPickImages}
                      onChange={(e) => setAutoPickImages(e.target.checked)}
                    />
                    <InfoIcon message="Auto-select images for each quiz question" />
                  </Flex>
                  <Flex justifyContent={"center"}>
                    <RaisedButton
                      gap={"small"}
                      variation="primary"
                      backgroundColor={"#1a90ff"}
                      isLoading={submitting}
                      onClick={async () => {
                        setSubmitting(true);
                        await questionsSubmitCallback();
                        setSubmitting(false);
                      }}
                      data-attr="done-generating-quiz"
                    >
                      Add to Quiz <ArrowRightIcon />
                    </RaisedButton>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Card>
        <Text>
          <b>Tokens Left:</b>{" "}
          {isLoading ? <GibblyLoader /> : tokenBalanceString}
        </Text>
      </Flex>
    </Authenticator>
  );
}
