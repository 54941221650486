import {
  Flex,
  StepperField,
  SwitchField,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../../../../components/RaisedButton";
import { LobbyOptions } from "../../Types/LobbyTypes";
import useLocalStorage from "../../../../hooks/useLocalStorage";

export interface ILobbyOptionsProps {
  currentOptions?: LobbyOptions;
  currentNumberOfTeams: number;
  onSubmitOptions: (
    options: LobbyOptions,
    numberOfTeams: number
  ) => void | Promise<void>;
  submitButtonText?: string;
}

export function LobbyOptionsForm(props: ILobbyOptionsProps) {
  const {
    onSubmitOptions,
    currentOptions,
    currentNumberOfTeams,
    submitButtonText,
  } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [numberOfTeams, setNumberOfTeams] =
    React.useState(currentNumberOfTeams);

  const [options, setOptions] = useLocalStorage<LobbyOptions>(
    "lobby-options",
    currentOptions || {
      randomNicknames: true,
      autoTeams: true,
      playersCanSwitchTeams: false,
      hostSpectating: true,
      playerMusic: true,
    }
  );

  return (
    <Flex direction="column">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Number of Teams
            </TableCell>
            <TableCell as="td" width={"200px"}>
              <Flex justifyContent={"end"} alignItems={"center"}>
                <StepperField
                   
                  label={undefined}
                  fontSize={{ base: "xxs", medium: "medium" }}
                  min={0}
                  max={8}
                  variation="quiet"
                  value={numberOfTeams}
                  onStepChange={(value) => setNumberOfTeams(value)}
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Nicknames
            </TableCell>
            <TableCell>
              <Flex justifyContent={"end"}>
                <SwitchField
                  label={options.randomNicknames ? "Random" : "Custom"}
                  isChecked={options.randomNicknames}
                  onChange={(e) =>
                    setOptions((prev) => ({
                      ...prev,
                      randomNicknames: e.target.checked,
                    }))
                  }
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Automatic Teams
            </TableCell>
            <TableCell as="td">
              <Flex justifyContent={"end"}>
                <SwitchField
                  label={options.autoTeams ? "Yes" : "No"}
                  isChecked={options.autoTeams}
                  onChange={(e) =>
                    setOptions((prev) => ({
                      ...prev,
                      autoTeams: e.target.checked,
                    }))
                  }
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Players Can Choose Team
            </TableCell>
            <TableCell as="td">
              <Flex justifyContent={"end"}>
                <SwitchField
                  label={options.playersCanSwitchTeams ? "Yes" : "No"}
                  isChecked={options.playersCanSwitchTeams}
                  onChange={(e) =>
                    setOptions((prev) => ({
                      ...prev,
                      playersCanSwitchTeams: e.target.checked,
                    }))
                  }
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Host Role
            </TableCell>
            <TableCell as="td">
              <Flex justifyContent={"end"}>
                <SwitchField
                  label={options.hostSpectating ? "Spectator" : "Player"}
                  isChecked={options.hostSpectating}
                  onChange={(e) =>
                    setOptions((prev) => ({
                      ...prev,
                      hostSpectating: e.target.checked,
                    }))
                  }
                />
              </Flex>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontSize={{ base: "xs", small: "medium" }} as="th">
              Player Music
            </TableCell>
            <TableCell as="td">
              <Flex justifyContent={"end"}>
                <SwitchField
                  label={options.playerMusic ? "On" : "Off"}
                  isChecked={options.playerMusic}
                  onChange={(e) =>
                    setOptions((prev) => ({
                      ...prev,
                      playerMusic: e.target.checked,
                    }))
                  }
                />
              </Flex>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Flex width={"100%"} justifyContent={"center"}>
        <RaisedButton
          variation="primary"
          width={"150px"}
          isLoading={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            try {
              await onSubmitOptions(options, numberOfTeams);
            } catch (err) {
              console.error(err);
            }
            setIsSubmitting(false);
          }}
        >
          {submitButtonText ?? "Continue"}
        </RaisedButton>
      </Flex>
    </Flex>
  );
}
