import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { Slide } from "../types/slideTypes";

export async function createSlide(slide: Slide) {

    const { lessonId, id, orderIndex, elements, background } = slide

    const slideItem: Omit<Slide, "createdAt" | "updatedAt"> = {
        lessonId,
        id: id,
        orderIndex,
        elements,
        background
    }

    try {
        await createDatabaseItem<Slide>("/slides", slideItem)
        return slideItem as Slide
    } catch (err) {

        throw err
    }

}