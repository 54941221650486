import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadProtectedImageFromURL } from "../features/quiz/utils/uploadProtectedImagFromURL";
import { S3ObjectProtected } from "../API";
import { AutoPickedImage, autoPickImage } from "../services/autoPickImage";
import { useUserContext } from "../context/UserContextProvider";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

type AutoPickImageArgs = {
  prompt: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: { width: number; height: number }
  ) => void | Promise<void>;
};

export function useAutoPickImageMutation() {
  const { hasTokens, user } = useUserContext();
  const [index, setIndex] = useState(1);
  const [psuedoLoading, setPsuedoLoading] = useState(false);
  const [imagesList, setImagesList] = useState<AutoPickedImage[] | null>(null);
  const queryClient = useQueryClient();

  const { mutateAsync, data, isLoading, ...restMutation } = useMutation({
    mutationFn: async ({ prompt, onUpload }: AutoPickImageArgs) => {
      if (hasTokens()) {
        const images = await autoPickImage(prompt, user?.identityId);
        if (images.length === 0) return null;
        const image = images[0];
        setImagesList(images);
        setIndex(1);
        queryClient.invalidateQueries(["user"]);
        await uploadProtectedImageFromURL(image.src, async (s3Image, url) => {
          await onUpload?.(
            { ...s3Image, alt: image.alt } as S3ObjectProtected,
            url.toString(),
            {
              width: image.width,
              height: image.height,
            }
          );
        });
        return image;
      }
      return null;
    },
    onError: (err: Error) => {
      console.error(err);
      // toast.error(err.message, { toastId: "auto-pick-image-" + prompt });
    },
    retry: 3,
  });

  const nextImage = useMemo(() => {
    const images = imagesList;
    const image = images?.[index];
    return image;
  }, [index, imagesList]);

  const mutate = useCallback(
    async ({ prompt, onUpload }: AutoPickImageArgs) => {
      if (!prompt || prompt === "") {
        toast.info(
          "QuickPic needs more info. Try adding a title, topic, or some text.",
          { toastId: "auto-pick-image" }
        );
        return null;
      }
      try {
        setPsuedoLoading(true);
        if (!data || !nextImage) {
          const image = await mutateAsync({
            prompt: prompt.slice(0, 300),
            onUpload,
          });
          setPsuedoLoading(false);
          return image;
        }

        await uploadProtectedImageFromURL(
          nextImage.src,
          async (s3Image, url) => {
            await onUpload?.(
              { ...s3Image, alt: nextImage.alt } as S3ObjectProtected,
              url.toString(),
              {
                width: nextImage.width,
                height: nextImage.height,
              }
            );
          }
        );
        setIndex((index) => index + 1);
        setPsuedoLoading(false);
        return nextImage;
      } catch (err) {
        setPsuedoLoading(false);
        return null;
      }
    },
    [mutateAsync, data, nextImage]
  );

  const loading = useMemo(
    () => isLoading || psuedoLoading,
    [isLoading, psuedoLoading]
  );

  return { autoPickImage: mutate, isLoading: loading, ...restMutation };
}
